// InvestDetail.js

import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  fetchInterestRate,
  firestore,
  getInvestData,
} from "../firebase.config";
import {
  updateDoc,
  doc as firestoreDoc,
  getDocs,
  where,
  increment,
  query,
  collection,
  addDoc,
  orderBy,
  doc,
  getDoc,
} from "firebase/firestore";
import {
  FaMoneyCheckAlt,
  FaMoneyBill,
  FaDollarSign,
  FaCalendarAlt,
  FaTradeFederation,
  FaChartBar,
} from "react-icons/fa";
import toast, { CheckmarkIcon, Toaster } from "react-hot-toast";
import { Loading } from "../Components/Loading";
import {
  AiFillRightCircle,
  AiFillRightSquare,
  AiOutlineCheckCircle,
} from "react-icons/ai";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import TransactionList from "../Components/TransactionList";
import BottomNav from "../Components/Footer";
import Investment from "./Investment";

const Portfolio = ({ userId }) => {
  const { investId } = useParams();
  const investmentId = parseInt(investId);
  const [investmentData, setInvestmentData] = useState("");
  const [actualAmount, setActualAmount] = useState();
  const [withdrawalAmount, setWithdrawalAmount] = useState("");
  const [intersetRate, setInterestRate] = useState("");
  const [showWithdrawalInput, setShowWithdrawalInput] = useState(false);
  const [loadingWithdrawal, setLoadingWithdrawal] = useState(false);

  const [transactions, setTransactions] = useState([]);
  const navigate = useNavigate();
  const uid = localStorage.getItem("token");
  const [showAllTransactions, setShowAllTransactions] = useState(true);
  const [isTransactionDetailsOpen, setTransactionDetailsOpen] = useState(false);
  function formatIndianNumberWithCommas(number) {
    // Convert the number to a string
    let numberString = number.toString();

    // Split the number into parts before and after the decimal point
    let parts = numberString.split(".");

    // Format the integer part with commas
    if (parts[0].length > 3) {
      // If the integer part has more than 3 digits, insert commas every two digits
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    // Join the parts back together
    let formattedNumber = parts.join(".");

    return formattedNumber;
  }

  // Example usage:

  const fetchTransactions = async () => {
    try {
      const transactionsQuerySnapshot = await getDocs(
        query(
          collection(firestore, "InvestTransactions"),
          where("userId", "==", uid),
          orderBy("transactionDate", "desc")
        )
      );

      const transactionsData = transactionsQuerySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      setTransactions(transactionsData);
    } catch (error) {
      console.error("Error fetching transactions:", error);
    }
  };

  const fetchData = async () => {
    try {
      const data = await getInvestData(uid);
      setInvestmentData(data);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchInterestRate = async () => {
    const docRef = doc(firestore, "Data", "Interest");
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      // console.log("Document data:", docSnap.data());
      setInterestRate(docSnap.data().interestRate);
    } else {
      // docSnap.data() will be undefined in this case
      console.log("No such document!");
    }
  };

  const calculateActualAmount = () => {
    if (investmentData.userId !== "") {
      const investTime = new Date(investmentData.investId);
      const currentTime = new Date();
      currentTime.setHours(0, 0);
      const currentAmount1 = new Date();
      const timeDifference = (currentAmount1 - currentTime) / 1000;
      const timeInDays = timeDifference / (24 * 60 * 60);
      const actualAmountValue =
        // investmentData.amount +
        (investmentData.amount * intersetRate * timeInDays) / 100;
      // const actualAmountValue = investmentData.amount + investmentData.collect;
      setActualAmount(
        formatIndianNumberWithCommas(actualAmountValue.toFixed(2))
      );
    }
  };

  // const collectInvestment = async () => {
  //   const collectAmount =
  //     actualAmount - investmentData.amount - investmentData.collect;

  //   try {
  //     const querySnapshot = await getDocs(
  //       query(
  //         collection(firestore, "Investments"),
  //         where("investId", "==", investmentId)
  //       )
  //     );

  //     if (querySnapshot.size > 0) {
  //       const documentRef = querySnapshot.docs[0].ref;

  //       await updateDoc(documentRef, {
  //         collect: increment(collectAmount),
  //       });
  //     } else {
  //       console.error("No document found for the given investId");
  //     }

  //     const userSnapshot = await getDocs(
  //       query(collection(firestore, "Users"), where("userId", "==", uid))
  //     );

  //     if (userSnapshot.size > 0) {
  //       const documentRef = userSnapshot.docs[0].ref;

  //       await updateDoc(documentRef, {
  //         currentAmount: increment(collectAmount),
  //       });
  //     } else {
  //       console.error("No document found for the given investId");
  //     }

  //     toast.success(
  //       `${formatIndianNumberWithCommas(
  //         parseFloat(collectAmount).toFixed(2)
  //       )} is credited in your wallet successfully`
  //     );

  //     fetchData();
  //   } catch (error) {
  //     console.error("Error collecting investment:", error);
  //   }
  // };

  const withdrawInvestment = async () => {
    try {
      setLoadingWithdrawal(true);
      const withdrawalAmountNumber = parseFloat(withdrawalAmount);

      if (withdrawalAmountNumber > investmentData.amount) {
        toast.error(
          `Available withdrawal amount is ₹${formatIndianNumberWithCommas(
            parseFloat(investmentData.amount).toFixed(2)
          )}`
        );
        setLoadingWithdrawal(false);

        return;
      }
      if (isNaN(withdrawalAmountNumber) || withdrawalAmountNumber <= 0) {
        toast.error("Please enter a valid investment amount.");
        setLoadingWithdrawal(false);

        return;
      }
      if (withdrawalAmountNumber < 100) {
        toast.error("Minimum Withdrawal is ₹100");
        setLoadingWithdrawal(false);

        return;
      }

      const querySnapshot = await getDocs(
        query(
          collection(firestore, "Investments"),
          where("investId", "==", investmentData.investId),
          where("userId", "==", uid)
        )
      );

      if (querySnapshot.size > 0) {
        const documentRef = querySnapshot.docs[0].ref;

        await updateDoc(documentRef, {
          amount: increment(-withdrawalAmountNumber),
        });
      } else {
        console.error("No document found for the given investId");
      }

      const userSnapshot = await getDocs(
        query(collection(firestore, "Users"), where("userId", "==", uid))
      );

      if (userSnapshot.size > 0) {
        const documentRef = userSnapshot.docs[0].ref;

        await updateDoc(documentRef, {
          currentAmount: increment(withdrawalAmountNumber),
        });
      } else {
        console.error("No document found for the given investId");
      }

      const transactionData = {
        userId: uid,
        investId: investmentData.investId,
        transactionType: "withdrawal",
        amount: withdrawalAmountNumber,
        transactionDate: new Date(),
      };

      const transactionDocRef = await addDoc(
        collection(firestore, "InvestTransactions"),
        transactionData
      );
      toast.success(
        `${withdrawalAmountNumber.toFixed(
          2
        )} is credited in your wallet successfully`
      );

      fetchData();
      setShowWithdrawalInput(false);
      fetchTransactions();
      setLoadingWithdrawal(false);
    } catch (error) {
      console.error("Error withdrawing investment:", error);
      setLoadingWithdrawal(false);
    }
  };

  useEffect(() => {
    fetchInterestRate();
    fetchData();
    fetchTransactions();
  }, [userId]);

  useEffect(() => {
    calculateActualAmount();
  });
  //   const intervalId = setInterval(() => {
  //     calculateActualAmount();
  //   }, 1000);

  //   return () => {
  //     clearInterval(intervalId);
  //   };
  // }, [investmentData]);

  const toggleTransactionDetails = () => {
    setTransactionDetailsOpen(!isTransactionDetailsOpen);
  };

  return (
    <>
      <div className="flex flex-col h-screen text-white">
        <div className="shadow ml-4">
          <Toaster toastOptions={{ duration: 2000 }} />
          <FaChartBar className="mr-2 text-xl mt-2" />
          <h1 className=" text-3xl font-bold flex items-center">Portfolio</h1>
        </div>

        <div className="flex-1 overflow-y-auto p-4 ">
          {investmentData ? (
            <>
              <div className="border-b p-2 border-gray-300 bg-gray-900 rounded-md ">
                <h2 className="text-lg font-bold flex items-center">
                  <FaDollarSign className="mr-2" />
                  Your Investment
                </h2>
                <p className="text-md mt-2 font-medium">
                  Invest Amount: ₹{" "}
                  {formatIndianNumberWithCommas(
                    parseFloat(investmentData.amount).toFixed(2)
                  )}
                </p>
                <p className="text-md mt-1 font-medium text-green-600">
                  {investmentData.status === "withdrawal" ? (
                    `Withdrawal Amount: ₹ ${investmentData.withdrawalAmount}`
                  ) : (
                    <>Interest Amount: ₹ {actualAmount} </>
                  )}
                </p>
              </div>
              <div className="firebase init functions bg-gray-900 rounded-md mt-4 border-b p-2">
                <h2 className="text-lg font-bold flex items-center">
                  <FaMoneyBill className="mr-2" />
                  Earnings
                </h2>
                <p className="text-md  mt-2 font-medium">
                  Total Earning: ₹{" "}
                  {formatIndianNumberWithCommas(
                    investmentData.collect.toFixed(2)
                  )}
                </p>
                <p className="text-md font-medium mt-1 text-green-600">
                  Daily Interest: ₹{" "}
                  {formatIndianNumberWithCommas(
                    parseFloat(
                      (intersetRate * investmentData.amount) / 100
                    ).toFixed(2)
                  )}
                  (~{intersetRate}%)
                </p>
              </div>
              {!showWithdrawalInput && (
                <div className="my-2">
                  <button
                    onClick={() => setShowWithdrawalInput(true)}
                    disabled={loadingWithdrawal}
                    className={`bg-green-600 w-full flex gap-1 items-center justify-center py-2.5 text-white rounded ${
                      loadingWithdrawal ? "opacity-50 cursor-not-allowed" : ""
                    }`}
                  >
                    <FaMoneyBill className="inline-block mr-2" />
                    Withdraw
                  </button>
                </div>
              )}
              {showWithdrawalInput && (
                <div className="my-4 relative">
                  <label
                    htmlFor="withdrawalAmount"
                    className="block text-md font-medium mb-2 float-left"
                  >
                    Withdrawal Amount:
                  </label>
                  <p className="float-right">
                    MAX WITHDRAWAL : ₹{" "}
                    {formatIndianNumberWithCommas(
                      parseFloat(investmentData.amount).toFixed(2)
                    )}
                  </p>

                  <input
                    type="number"
                    id="withdrawalAmount"
                    name="withdrawalAmount"
                    value={withdrawalAmount}
                    onChange={(e) => {
                      const enteredValue = e.target.value;
                      const maxValue = investmentData.amount;

                      // Check if entered value is empty or a valid number within the limit
                      if (
                        enteredValue === "" ||
                        (!isNaN(enteredValue) &&
                          parseFloat(enteredValue) <= maxValue)
                      ) {
                        setWithdrawalAmount(enteredValue);
                      }
                    }}
                    className="pl-7 bg-gray-700 h-12 text-white  outline-none  border-gray-300  mt-1 p-2 w-full border rounded-md focus:outline-none focus:ring focus:border-blue-300"
                  />
                  <button
                    onClick={withdrawInvestment}
                    disabled={loadingWithdrawal}
                    className={`bg-green-600 w-full flex gap-1 items-center mt-2 justify-center py-2.5 text-white rounded ${
                      loadingWithdrawal ? "opacity-50 cursor-not-allowed" : ""
                    }`}
                  >
                    <FaMoneyBill className="inline-block mr-2" />
                    {loadingWithdrawal ? <>Withdrawing...</> : <>Withdraw</>}
                  </button>
                </div>
              )}
              {/* Filter control */}
              <div className="p-4 rounded-lg float-left w-full bg-gray-800 text-gray-300">
                <p className="text-md font-medium ">
                  Total Transactions: {transactions.length}
                </p>
                <button
                  className="bg-blue-500 mt-2 float-right text-white font-medium py-2 px-4 rounded focus:outline-none focus:shadow-outline-blue hover:bg-blue-600"
                  onClick={toggleTransactionDetails}
                >
                  Show Transactions
                </button>
              </div>
              {/* Transaction details modal */}
              {isTransactionDetailsOpen && (
                <div
                  className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center"
                  onClick={toggleTransactionDetails}
                >
                  <div
                    className="bg-gray-800 p-4 w-96 rounded-md max-h-[80vh] overflow-y-auto"
                    onClick={(e) => e.stopPropagation()}
                  >
                    {/* Add your transaction details content here */}
                    <button
                      className="float-right"
                      onClick={toggleTransactionDetails}
                    >
                      Close
                    </button>
                    <TransactionList
                      transactions={showAllTransactions ? transactions : []}
                    />
                  </div>
                </div>
              )}
              <div className=" items-center">
                <AiOutlineCheckCircle className="float-left mt-1 w-5 h-5 text-green-600" />
                <p
                  id="textView12"
                  className="ml-2 text-lg font-poppins-bold text-green-600"
                >
                  Your Money is 100% safe with us.
                </p>
              </div>
              <p className="font-poppins mt-2 text-base text-green-200">
                * Interest will be auto-credit in your wallet at the end of the
                day.{" "}
              </p>{" "}
            </>
          ) : (
            <Loading />
          )}
        </div>
      </div>
      <div className="mt-4">TapCent</div>

      <BottomNav />
    </>
  );
};

export default Portfolio;
