import React, { useState } from "react";
import { auth } from "../firebase.config";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";

const PhoneAuth = () => {
  const [phone, setPhone] = useState("+91");
  const [hasFilled, setHasFilled] = useState(false);
  const [otp, setOtp] = useState("");

  const generateRecaptcha = () => {
    window.recaptchaVerifier = new RecaptchaVerifier(
      "recaptcha",
      {
        size: "invisible",
        callback: (response) => {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
          // ...
        },
      },
      auth
    );
  };

  const handleSend = (event) => {
    event.preventDefault();
    setHasFilled(true);
    generateRecaptcha();
    let appVerifier = window.recaptchaVerifier;
    signInWithPhoneNumber(auth, phone, appVerifier)
      .then((confirmationResult) => {
        // SMS sent. Prompt user to type the code from the message, then sign the
        // user in with confirmationResult.confirm(code).
        window.confirmationResult = confirmationResult;
      })
      .catch((error) => {
        // Error; SMS not sent
        console.log(error);
      });
  };

  const verifyOtp = (event) => {
    let otp = event.target.value;
    setOtp(otp);

    if (otp.length === 6) {
      // verifu otp
      let confirmationResult = window.confirmationResult;
      confirmationResult
        .confirm(otp)
        .then((result) => {
          // User signed in successfully.
          let user = result.user;
          console.log(user);
          alert("User signed in successfully");
          // ...
        })
        .catch((error) => {
          // User couldn't sign in (bad verification code?)
          // ...
          alert("User couldn't sign in (bad verification code?)");
        });
    }
  };

  if (!hasFilled) {
    return (
      <div className="bg-gray-950 flex justify-center items-center h-screen">
        <div className="w-80 bg-white rounded-md shadow-md p-5">
          <div className="p-5 text-xl font-bold">Enter your phone number</div>
          <form onSubmit={handleSend} className="w-full">
            <input
              className="w-full mb-4 p-2 border border-gray-300 rounded-md"
              type="tel"
              autoComplete="off"
              placeholder="Phone Number"
              value={phone}
              onChange={(event) => setPhone(event.target.value)}
            />
            <button
              type="submit"
              className="w-full bg-blue-500 text-white hover:bg-blue-700 p-2 rounded-md"
            >
              Send Code
            </button>
          </form>
        </div>
        <div id="recaptcha"></div>
      </div>
    );
  } else {
    return (
      <div className="app__container flex justify-center items-center h-screen">
        <div className="w-80 bg-white rounded-md shadow-md p-5">
          <div className="p-5 text-xl font-bold">Enter the OTP</div>
          <input
            className="w-full mb-4 p-2 border border-gray-300 rounded-md"
            type="text"
            placeholder="OTP"
            value={otp}
            onChange={verifyOtp}
          />
        </div>
        <div id="recaptcha"></div>
      </div>
    );
  }
};

export default PhoneAuth;
