import React, { useState, useEffect } from "react";
import { collection, getDocs, where, query, orderBy } from "firebase/firestore";
import { firestore } from "../firebase.config"; // Adjust the import based on your Firestore configuration
import { useNavigate } from "react-router-dom";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { FaMoneyCheck, FaUser } from "react-icons/fa";

const TransactionList = () => {
  const navigate = useNavigate();
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filterType, setFilterType] = useState("deposit"); // "all", "deposit", or "withdrawal"
  const uid = localStorage.getItem("token");
  function formatIndianNumberWithCommas(number) {
    // Convert the number to a string
    let numberString = number.toString();

    // Split the number into parts before and after the decimal point
    let parts = numberString.split(".");

    // Format the integer part with commas
    if (parts[0].length > 3) {
      // If the integer part has more than 3 digits, insert commas every two digits
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    // Join the parts back together
    let formattedNumber = parts.join(".");

    return formattedNumber;
  }

  // Example usage:

  useEffect(() => {
    const fetchTransactions = async () => {
      try {
        setLoading(true);

        const transactionsCollection = collection(firestore, "Transactions");
        const transactionsQuery = query(
          transactionsCollection,
          where("userId", "==", uid),
          orderBy("timestamp", "desc")
        );

        const snapshot = await getDocs(transactionsQuery);

        const fetchedTransactions = [];
        snapshot.forEach((doc) => {
          fetchedTransactions.push(doc.data());
        });

        setTransactions(fetchedTransactions);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching transactions:", error);
        setLoading(false);
      }
    };

    fetchTransactions();
  }, []); // Make sure to include any dependencies if needed

  const handleFilterChange = (filter) => {
    setFilterType(filter);
  };

  const filteredTransactions = transactions.filter((transaction) => {
    if (filterType === "all") {
      return true;
    } else {
      return transaction.type === filterType;
    }
  });
  // const getStatusStyle = () => {
  //   if (transactions.status === "pending") {
  //     return "bg-gray-500 text-white";
  //   } else if (transaction.status === "success") {
  //     return "bg-green-500 text-white";
  //   } else {
  //     // Add more conditions as needed
  //     return "bg-gray-500 text-white";
  //   }
  // };

  return (
    <div className="text-white max-w-3xl mx-auto p-4">
      <h2 className="text-2xl font-bold mb-4">
        {" "}
        <span
          className="text-white cursor-pointer"
          onClick={() => navigate(-1)}
        >
          &#8592;{" "}
        </span>
        Transaction History
      </h2>

      <div className="mb-8 flex space-x-4">
        <button
          onClick={() => handleFilterChange("deposit")}
          className={` px-6 py-2 rounded ${
            filterType === "deposit"
              ? "bg-blue-500 text-white"
              : "bg-gray-800 text-gray-200"
          } hover:bg-blue-600 transition duration-300`}
        >
          Deposits
        </button>
        <button
          onClick={() => handleFilterChange("withdrawal")}
          className={` px-6 py-2 rounded ${
            filterType === "withdrawal"
              ? "bg-blue-500 text-white"
              : "bg-gray-800 text-gray-200"
          } hover:bg-blue-600 transition duration-300`}
        >
          Withdrawals
        </button>
      </div>

      {loading ? (
        <p className="text-gray-300">Loading...</p>
      ) : filteredTransactions.length === 0 ? (
        <p className="text-gray-300">No transactions found.</p>
      ) : (
        <ul className="space-y-4">
          {filteredTransactions.map((transaction, index) => (
            <li key={index} className="bg-gray-800 rounded  shadow-md ">
              {transaction.type == "deposit" ? (
                <div className="text-gray-400">
                  <div className="flex flex-col p-4 border-b border-gray-300">
                    <div className="flex justify-between items-center mb-2">
                      <div className="flex items-center">
                        {transaction.status === "pending" && (
                          <span className="bg-gray-300 text-black px-2 py-1 rounded">
                            Pending
                          </span>
                        )}

                        {transaction.status === "success" && (
                          <span className="bg-green-600 text-white px-2 py-1 rounded">
                            Completed
                          </span>
                        )}

                        {transaction.status === "failed" && (
                          <span className="bg-red-600 text-white px-2 py-1 rounded">
                            Failed
                          </span>
                        )}
                      </div>

                      <div className="text-sm text-gray-500">
                        {transaction.timestamp.toDate().toLocaleString()}
                      </div>
                    </div>

                    <div className="flex flex-col md:flex-row">
                      <div className="md:w-1/2">
                        <div className="mb-2">
                          Deposit({transaction.depositMethod}){" "}
                        </div>
                        <div>
                          Ref:{" "}
                          <span className="font-bold">{transaction.id}</span>
                        </div>
                      </div>
                      <div className="md:w-1/2 mt-2 text-right">
                        <div className="text-2xl font-bold text-green-500">
                          ₹
                          <span className="tracking-wider">
                            {formatIndianNumberWithCommas(
                              transaction.amount.toFixed(2)
                            )}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="text-gray-400">
                  <div className="flex flex-col p-4 border-b border-gray-300">
                    <div className="flex justify-between items-center mb-2">
                      <div className="flex items-center">
                        {transaction.status === "pending" && (
                          <span className="bg-gray-300 text-black px-2 py-1 rounded">
                            Pending
                          </span>
                        )}

                        {transaction.status === "success" && (
                          <span className="bg-green-600 text-white px-2 py-1 rounded">
                            Completed
                          </span>
                        )}

                        {transaction.status === "failed" && (
                          <span className="bg-red-600 text-white px-2 py-1 rounded">
                            Failed
                          </span>
                        )}
                      </div>
                      <div className="text-sm text-gray-500">
                        {transaction.timestamp.toDate().toLocaleString()}
                      </div>
                    </div>

                    <div className="flex flex-col md:flex-row">
                      <div className="md:w-1/2">
                        <div className="mb-2">
                          Details(
                          {transaction.withdrawalMethod == "upi"
                            ? "UPI"
                            : "Bank"}
                          ):
                          <span className="font-bold">
                            {transaction.withdrawalMethod == "upi"
                              ? transaction.upiId
                              : transaction.accountNo}
                          </span>
                        </div>
                        <div>
                          Ref:{" "}
                          <span className="font-bold">{transaction.id}</span>
                        </div>
                      </div>
                      <div className="md:w-1/2 mt-2 text-right">
                        <div className="text-2xl font-bold text-green-500">
                          ₹
                          <span className="tracking-wider">
                            {formatIndianNumberWithCommas(
                              transaction.amount.toFixed(2)
                            )}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {/* <div>
                <strong className="text-blue-500">Amount:</strong> ₹
                {formatIndianNumberWithCommas(
                  parseFloat(transaction.amount).toFixed(2)
                )}
                , <br />
                <strong className="text-blue-500">Type:</strong>{" "}
                {transaction.type == "withdrawal" &&
                  "withdrawal (" + transaction.withdrawalMethod + ")"}
                {transaction.type == "deposit" && transaction.type}
                , <br />
                <strong className="text-blue-500">Status:</strong>{" "}
                {transaction.status}
                <br />
                <strong className="text-blue-500">Time:</strong>{" "}
                {transaction.timestamp.toDate().toLocaleString()}
                <br />
                <strong className="text-blue-500 ">Transaction ID: </strong>
                {transaction.id}, <br />
              </div> */}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default TransactionList;
