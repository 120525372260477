// InvestDetail.js

import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { firestore, getInvestData } from "../firebase.config";
import {
  updateDoc,
  doc as firestoreDoc,
  getDocs,
  where,
  increment,
  query,
  collection,
  addDoc,
  orderBy,
} from "firebase/firestore";
import {
  FaMoneyCheckAlt,
  FaMoneyBill,
  FaDollarSign,
  FaCalendarAlt,
} from "react-icons/fa";
import toast, { CheckmarkIcon, Toaster } from "react-hot-toast";
import { Loading } from "../Components/Loading";
import { AiFillRightCircle, AiFillRightSquare } from "react-icons/ai";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import TransactionList from "../Components/TransactionList";

const InvestDetail = ({ userId }) => {
  const { investId } = useParams();
  const investmentId = parseInt(investId);
  const [investmentData, setInvestmentData] = useState("");
  const [actualAmount, setActualAmount] = useState();
  const [withdrawalAmount, setWithdrawalAmount] = useState("");
  const [showWithdrawalInput, setShowWithdrawalInput] = useState(false);
  const [transactions, setTransactions] = useState([]);
  const [showAllTransactions, setShowAllTransactions] = useState(true); // Added filter state
  const navigate = useNavigate();
  const uid = localStorage.getItem("token");

  function formatIndianNumberWithCommas(number) {
    // Convert the number to a string
    let numberString = number.toString();

    // Split the number into parts before and after the decimal point
    let parts = numberString.split(".");

    // Format the integer part with commas
    if (parts[0].length > 3) {
      // If the integer part has more than 3 digits, insert commas every two digits
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    // Join the parts back together
    let formattedNumber = parts.join(".");

    return formattedNumber;
  }

  // Example usage:

  const fetchTransactions = async () => {
    try {
      const transactionsQuerySnapshot = await getDocs(
        query(
          collection(firestore, "InvestTransactions"),
          where("userId", "==", uid),
          orderBy("transactionDate", "desc")
        )
      );

      const transactionsData = transactionsQuerySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      setTransactions(transactionsData);
    } catch (error) {
      console.error("Error fetching transactions:", error);
    }
  };

  const fetchData = async () => {
    try {
      const data = await getInvestData(uid);
      setInvestmentData(data);
    } catch (error) {
      console.log(error);
    }
  };

  // const calculateActualAmount = () => {
  //   if (investmentData.userId !== "") {
  //     const investTime = new Date(investmentData.investId);
  //     const currentTime = new Date();

  //     const timeDifference = (currentTime - investTime) / 1000;
  //     const timeInDays = timeDifference / (24 * 60 * 60);
  //     const actualAmountValue = investmentData.amount + investmentData.collect;
  //     setActualAmount(actualAmountValue.toFixed(2));
  //   }
  // };

  // // const collectInvestment = async () => {
  //   const collectAmount =
  //     actualAmount - investmentData.amount - investmentData.collect;

  //   try {
  //     const querySnapshot = await getDocs(
  //       query(
  //         collection(firestore, "Investments"),
  //         where("investId", "==", investmentId)
  //       )
  //     );

  //     if (querySnapshot.size > 0) {
  //       const documentRef = querySnapshot.docs[0].ref;

  //       await updateDoc(documentRef, {
  //         collect: increment(collectAmount),
  //       });
  //     } else {
  //       console.error("No document found for the given investId");
  //     }

  //     const userSnapshot = await getDocs(
  //       query(collection(firestore, "Users"), where("userId", "==", uid))
  //     );

  //     if (userSnapshot.size > 0) {
  //       const documentRef = userSnapshot.docs[0].ref;

  //       await updateDoc(documentRef, {
  //         currentAmount: increment(collectAmount),
  //       });
  //     } else {
  //       console.error("No document found for the given investId");
  //     }

  //     toast.success(
  //       `${formatIndianNumberWithCommas(
  //         parseFloat(collectAmount).toFixed(2)
  //       )} is credited in your wallet successfully`
  //     );

  //     fetchData();
  //   } catch (error) {
  //     console.error("Error collecting investment:", error);
  //   }
  // };

  const withdrawInvestment = async () => {
    try {
      const withdrawalAmountNumber = parseFloat(withdrawalAmount);
      if (withdrawalAmountNumber > investmentData.amount) {
        toast.error(
          `Max Withdrawal is ${formatIndianNumberWithCommas(
            parseFloat(investmentData.amount).toFixed(2)
          )}`
        );
        return;
      }
      const querySnapshot = await getDocs(
        query(
          collection(firestore, "Investments"),
          where("investId", "==", investmentData.investId),
          where("userId", "==", uid)
        )
      );

      if (querySnapshot.size > 0) {
        const documentRef = querySnapshot.docs[0].ref;

        await updateDoc(documentRef, {
          amount: increment(-withdrawalAmountNumber),
        });
      } else {
        console.error("No document found for the given investId");
      }

      const userSnapshot = await getDocs(
        query(collection(firestore, "Users"), where("userId", "==", uid))
      );

      if (userSnapshot.size > 0) {
        const documentRef = userSnapshot.docs[0].ref;

        await updateDoc(documentRef, {
          currentAmount: increment(withdrawalAmountNumber),
        });
      } else {
        console.error("No document found for the given investId");
      }

      const transactionData = {
        userId: uid,
        investId: investmentData.investId,
        transactionType: "withdrawal",
        amount: withdrawalAmountNumber,
        transactionDate: new Date(),
      };

      const transactionDocRef = await addDoc(
        collection(firestore, "InvestTransactions"),
        transactionData
      );
      toast.success(
        `${withdrawalAmountNumber.toFixed(
          2
        )} is credited in your wallet successfully`
      );

      fetchData();
      setShowWithdrawalInput(false);
      fetchTransactions();
    } catch (error) {
      console.error("Error withdrawing investment:", error);
    }
  };

  useEffect(() => {
    fetchData();
    fetchTransactions();
  }, [userId]);

  // useEffect(() => {
  //   calculateActualAmount();

  //   const intervalId = setInterval(() => {
  //     calculateActualAmount();
  //   }, 1000);

  //   return () => {
  //     clearInterval(intervalId);
  //   };
  // }, [investmentData]);

  return (
    <div className="flex flex-col h-screen text-white">
      <div className="shadow p-4">
        <Toaster toastOptions={{ duration: 2000 }} />
        <div className="mb-4">
          <span
            className="text-white cursor-pointer"
            onClick={() => navigate(-1)}
          >
            <FontAwesomeIcon icon={faArrowLeft} className="mr-2" />
            Back
          </span>
        </div>

        <hr />
        <h1 className="text-xl mt-2 font-bold flex items-center">
          <FaMoneyCheckAlt className="mr-2" />
          Investment Details
        </h1>
      </div>

      <div className="flex-1 overflow-y-auto p-4 ">
        {investmentData ? (
          <>
            <div className="border-b p-2 border-gray-300 bg-gray-900 rounded-md ">
              <h2 className="text-lg font-bold flex items-center">
                <FaDollarSign className="mr-2" />
                Your Investment
              </h2>
              <p className="text-md font-medium">
                Invest Amount: ₹
                {formatIndianNumberWithCommas(
                  parseFloat(investmentData.amount).toFixed(2)
                )}
              </p>
              <p className="text-md font-medium text-green-600">
                {investmentData.status === "withdrawal" ? (
                  `Withdrawal Amount: ₹${investmentData.withdrawalAmount}`
                ) : (
                  <>Actual Amount: ₹{actualAmount} </>
                )}
              </p>
            </div>

            <div className="firebase init functions border-gray-300 p-2">
              <h2 className="text-lg font-bold flex items-center">
                <FaMoneyBill className="mr-2" />
                Earnings
              </h2>
              <p className="text-md font-medium">
                Investment Earn: ₹
                {formatIndianNumberWithCommas(
                  parseFloat(investmentData.collect).toFixed(2)
                )}
              </p>
              <p className="text-md font-medium text-green-600">
                Daily Income: ₹
                {(investmentData.interest * investmentData.amount) / 100}
              </p>
            </div>

            {!showWithdrawalInput && (
              <div className="p-4">
                <button
                  className="bg-blue-500 mt-2 text-white font-medium py-2 px-4 rounded focus:outline-none focus:shadow-outline-blue hover:bg-blue-600"
                  onClick={() => setShowWithdrawalInput(true)}
                  disabled={investmentData.status === "withdrawal"}
                >
                  <FaMoneyBill className="inline-block mr-2" />
                  Withdraw Amount
                </button>
              </div>
            )}

            {showWithdrawalInput && (
              <div className="p-4">
                <label
                  htmlFor="withdrawalAmount"
                  className="block text-md font-medium mb-2 float-left"
                >
                  Withdrawal Amount:
                </label>
                <p className="float-right">
                  MAX WITHDRAWAL :{" "}
                  {formatIndianNumberWithCommas(
                    parseFloat(investmentData.amount).toFixed(2)
                  )}
                </p>

                <input
                  type="number"
                  id="withdrawalAmount"
                  name="withdrawalAmount"
                  value={withdrawalAmount}
                  onChange={(e) => setWithdrawalAmount(e.target.value)}
                  className="pl-7 bg-gray-700 h-12 text-white  outline-none  border-gray-300  mt-1 p-2 w-full border rounded-md focus:outline-none focus:ring focus:border-blue-300"
                />
                <button
                  className="bg-blue-500 mt-2 text-white font-medium py-2 px-4 rounded focus:outline-none focus:shadow-outline-blue hover:bg-blue-600"
                  onClick={withdrawInvestment}
                  disabled={investmentData.status === "withdrawal"}
                >
                  <FaMoneyBill className="inline-block mr-2" />
                  Withdraw Amount
                </button>
              </div>
            )}

            {/* Filter control */}
            <div className="p-4">
              <label className="block text-md font-medium mb-2">
                Show All Transactions:
                <input
                  type="checkbox"
                  checked={showAllTransactions}
                  onChange={() => setShowAllTransactions(!showAllTransactions)}
                  className="ml-2"
                />
              </label>
            </div>

            <TransactionList
              transactions={showAllTransactions ? transactions : []}
            />

            <div className="p-4">
              <p className="text-md font-medium">
                Invested On: {investmentData.time.toDate().toLocaleDateString()}
              </p>
              {investmentData.status === "withdrawal" ? (
                <p className="text-md font-medium">
                  Withdrawal On:{" "}
                  {investmentData.time.toDate().toLocaleDateString()}
                </p>
              ) : null}

              <p className="text-md font-medium">
                Interest Per Day: {investmentData.interest}%
              </p>
              <p className="text-md font-medium">
                Transaction ID: {investmentData.investId}
              </p>
              <p className="text-md font-medium">
                Payment ID: {investmentData.paymentId}
              </p>
            </div>
          </>
        ) : (
          <Loading />
        )}
      </div>
    </div>
  );
};

export default InvestDetail;
