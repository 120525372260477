import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { Timestamp, getFirestore } from "firebase/firestore";
import {
  doc,
  getDoc,
  collection,
  query,
  where,
  getDocs,
} from "firebase/firestore";

const firebaseConfig = {
  // Your Firebase project configuration
  apiKey: "AIzaSyAvaJyj4mL6Anql5HZLrBvRva6YwmAAb9Q",
  authDomain: "noxus-aa1d5.firebaseapp.com",
  projectId: "noxus-aa1d5",
  storageBucket: "noxus-aa1d5.appspot.com",
  messagingSenderId: "224895727551",
  appId: "1:224895727551:web:4a70dd58ac3a4a3e4d930a",
  measurementId: "G-1305KF0RFW",
};

export const getUserData = async (userId) => {
  try {
    const usersCollectionRef = collection(firestore, "Users");
    const userQuery = query(usersCollectionRef, where("userId", "==", userId));
    const userQuerySnapshot = await getDocs(userQuery);

    if (!userQuerySnapshot.empty) {
      // Assuming there's only one document per user
      const userData = userQuerySnapshot.docs[0].data();
      //console.log(userData);
      return userData;
    } else {
      //  console.log("User not found.");
      return null;
    }
  } catch (error) {
    console.error("Error fetching user data:", error);
    throw error;
  }
};
export const getInvestData = async (userId) => {
  try {
    const usersCollectionRef = collection(firestore, "Investments");
    const userQuery = query(usersCollectionRef, where("userId", "==", userId));
    const userQuerySnapshot = await getDocs(userQuery);

    if (!userQuerySnapshot.empty) {
      // Assuming there's only one document per user
      const userData = userQuerySnapshot.docs[0].data();
      //console.log(userData);
      return userData;
    } else {
      //  console.log("Investment not found.");
      return null;
    }
  } catch (error) {
    console.error("Error fetching user data:", error);
    throw error;
  }
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const firestore = getFirestore(app);

export { auth, firestore, app };
