import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useNavigate } from "react-router-dom";

export const PrivacyPolicy = () => {
  const navigate = useNavigate();

  return (
    <div className=" text-white max-w-2xl mx-auto p-4">
      <div className="mb-4">
        <span
          className="text-white cursor-pointer"
          onClick={() => navigate(-1)}
        >
          <FontAwesomeIcon icon={faArrowLeft} className="mr-2" />
          Back
        </span>
      </div>
      <h1 className="text-3xl font-bold mb-4">Privacy Policy</h1>
      <p className="mb-4">
        This Privacy Policy outlines how we collect, use, and protect your
        personal information when you use our financial services.
      </p>

      <h5 className="text-xl font-bold mb-2">1. Information We Collect</h5>
      <p>
        We collect personal information, including but not limited to your name,
        email address, and contact details when you register for our services.
      </p>

      <h5 className="text-xl font-bold mb-2">2. How We Use Your Information</h5>
      <p>
        We use the collected information to provide and improve our financial
        services, communicate with you, and ensure the security of your account.
      </p>

      <h5 className="text-xl font-bold mb-2">3. Information Sharing</h5>
      <p>
        We do not share your personal information with third parties except as
        required by law or with your explicit consent.
      </p>

      <h5 className="text-xl font-bold mb-2">4. Security</h5>
      <p>
        We take reasonable measures to protect your personal information from
        unauthorized access or disclosure. However, no method of transmission
        over the internet or electronic storage is 100% secure.
      </p>

      <h5 className="text-xl font-bold mb-2">5. Cookies</h5>
      <p>
        We use cookies to enhance your experience on our website. You can set
        your browser to refuse cookies or to indicate when a cookie is being
        sent.
      </p>

      <h5 className="text-xl font-bold mb-2">6. Changes to Privacy Policy</h5>
      <p>
        We reserve the right to update our Privacy Policy. Changes will be
        effective immediately upon posting to the website.
      </p>

      <h5 className="text-xl font-bold mb-2">7. Contact Us</h5>
      <p>
        If you have any questions or concerns about our Privacy Policy, please
        contact us on telegram.
      </p>
    </div>
  );
};
