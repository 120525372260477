import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useNavigate,
  Link,
  useParams,
  useLocation,
} from "react-router-dom";
import { auth, firestore } from "../firebase.config";

import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import {
  collection,
  addDoc,
  doc,
  updateDoc,
  getDocs,
  query,
  where,
  setDoc,
} from "firebase/firestore";
import { toast, Toaster } from "react-hot-toast";
import OtpInput from "otp-input-react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { BsFillShieldLockFill } from "react-icons/bs";
import { CgSpinner } from "react-icons/cg";
import Cookies from "js-cookie";

const Login = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const refCode = params.get("refCode");
  const [otp, setOtp] = useState("");
  const [ph, setPh] = useState("");
  const [loading, setLoading] = useState(false);
  const [showOTP, setShowOTP] = useState(false);
  const [user, setUser] = useState(null);
  const navigate = useNavigate();
  const [isChecked, setIsChecked] = useState(true);

  useEffect(() => {
    checkUserAuthentication();
    window.recaptchaVerifier = new RecaptchaVerifier(auth, "recaptcha", {
      size: "invisible",
      callback: (response) => {},
      "expired-callback": () => {},
    });
  }, []);

  const checkUserAuthentication = () => {
    const storedUser = JSON.parse(localStorage.getItem("user"));
    const storedToken = localStorage.getItem("token");
    const expirationTime = localStorage.getItem("expirationTime");
    const cookie = Cookies.get("authToken");

    if ((storedUser && storedToken && expirationTime) || cookie) {
      const isTokenValid = new Date().getTime() < Number(expirationTime);

      if (isTokenValid) {
        setUser(storedUser);
        navigate("/dashboard");
      } else {
        clearLocalStorage();
      }
    }
  };

  const clearLocalStorage = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    localStorage.removeItem("expirationTime");
    Cookies.remove("authToken");
  };

  const saveUserToLocalStorage = (userData) => {
    const expirationTime = new Date().getTime() + 30 * 24 * 60 * 60 * 1000;
    localStorage.setItem("user", JSON.stringify(userData));
    localStorage.setItem("token", userData.uid);
    localStorage.setItem("expirationTime", String(expirationTime));
    Cookies.set("authToken", userData.uid, { expires: 7 });
  };

  const onSignup = () => {
    setLoading(true);

    if (ph === null || ph.length !== 12) {
      toast.error("Enter a valid phone number");
      setLoading(false);
      return;
    }

    const appVerifier = window.recaptchaVerifier;
    const formatPh = "+" + ph;

    signInWithPhoneNumber(auth, formatPh, appVerifier)
      .then((confirmationResult) => {
        window.confirmationResult = confirmationResult;

        setShowOTP(true);
        toast.success("OTP sent successfully!");
        setLoading(false);
      })
      .catch((error) => {
        handleSignupError(error);
      });
  };

  const handleSignupError = (error) => {
    console.error(error);
    if (error.code === "auth/invalid-verification-code") {
      toast.error("Invalid OTP");
    }
    if (
      error.code === "auth/too-many-requests" ||
      error.code === "auth/invalid-phone-number"
    ) {
      toast.error("Getting some error! Please try later.");
    }

    setLoading(false);
  };

  const onOTPVerify = async () => {
    setLoading(true);

    try {
      const res = await window.confirmationResult.confirm(otp);
      const userQuerySnapshot = await getDocs(
        query(
          collection(firestore, "Users"),
          where("userId", "==", res.user.uid)
        )
      );

      if (userQuerySnapshot.size === 0) {
        await createNewUser(res.user.uid);
      }

      setUser(res.user);
      saveUserToLocalStorage(res.user, refCode);
      setLoading(false);

      navigate("/dashboard");
    } catch (err) {
      handleOTPVerificationError(err);
    }
  };

  const createNewUser = async (uid) => {
    const userDocRef = doc(collection(firestore, "Users"), uid);

    await setDoc(userDocRef, {
      userId: uid,
      name: "TapCent User",
      phone: ph,
      registerDate: new Date(),
      currentAmount: 0,
      totalDeposit: 0,
      totalWithdrawal: 0,
      referUid: refCode,
    });

    const investDocRef = doc(collection(firestore, "Investments"), uid);

    await setDoc(investDocRef, {
      userId: uid,
      amount: 0,
      collect: 0,
      interest: 2,
      status: "active",
      time: new Date(),
      transactionType: "investment",
      investId: new Date().getTime(),
    });
  };

  const handleOTPVerificationError = (err) => {
    console.error(err);
    setLoading(false);

    if (err.code === "auth/code-expired") {
      toast.error("OTP has expired. Please request a new one.");
    } else {
      toast.error("Error verifying OTP. Please try again.");
    }
  };

  return (
    <>
      <section className="flex items-center justify-center h-screen">
        <div>
          <Toaster toastOptions={{ duration: 2000 }} />
          <div id="recaptcha" />
          <div className="w-80 flex flex-col gap-4 rounded-lg p-4">
            <img
              src={require("../Assets/logo.png")}
              alt="Logo"
              className="mb-10"
            />

            {showOTP ? (
              <>
                <label
                  htmlFor="otp"
                  className="font-bold text-xl text-white text-center"
                >
                  Enter your OTP
                </label>
                <OtpInput
                  value={otp}
                  onChange={setOtp}
                  OTPLength={6}
                  otpType="number"
                  disabled={false}
                  autoFocus
                  className="opt-container"
                />
                <button
                  onClick={onOTPVerify}
                  disabled={loading}
                  className={`bg-green-600 w-full flex gap-1 items-center justify-center py-2.5 text-white rounded ${
                    loading ? "opacity-50 cursor-not-allowed" : ""
                  }`}
                >
                  {loading && (
                    <CgSpinner size={20} className="mt-1 animate-spin" />
                  )}
                  <span>Verify OTP</span>
                </button>
              </>
            ) : (
              <>
                <label
                  htmlFor=""
                  className="font-bold text-xl text-white text-center"
                >
                  Verify your phone number
                </label>
                <PhoneInput country={"in"} value={ph} onChange={setPh} />
                <label className="flex items-center gap-2">
                  <input
                    type="checkbox"
                    checked={isChecked}
                    onChange={() => setIsChecked(!isChecked)}
                  />
                  <span className="text-white">
                    I agree to the{" "}
                    <Link to="/terms-conditions">terms & condition</Link>
                  </span>
                </label>
                <button
                  onClick={onSignup}
                  disabled={loading || !isChecked}
                  className={`bg-green-600 w-full flex gap-1 items-center justify-center py-2.5 text-white rounded ${
                    loading || !isChecked ? "opacity-50 cursor-not-allowed" : ""
                  }`}
                >
                  {loading && (
                    <CgSpinner size={20} className="mt-1 animate-spin" />
                  )}
                  <span>Send code via SMS</span>
                </button>
              </>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default Login;
