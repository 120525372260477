import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  collection,
  doc,
  updateDoc,
  addDoc,
  increment,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { firestore, getUserData } from "../firebase.config";
import toast, { Toaster } from "react-hot-toast";
import { CgSpinner } from "react-icons/cg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faMoneyBill,
  faMoneyCheckAlt,
  faPhoneAlt,
  faShieldAlt,
  faTimeline,
} from "@fortawesome/free-solid-svg-icons";

const Withdrawal = () => {
  const [amount, setAmount] = useState("");
  const [loading, setLoading] = useState(false);
  const [bankDetails, setBankDetails] = useState(null);
  const [upiDetails, setUpiDetails] = useState(null);
  const [userData, setUserData] = useState(null);
  const [withdrawalMethod, setWithdrawalMethod] = useState("upi");
  const uid = localStorage.getItem("token");
  const navigate = useNavigate();
  function formatIndianNumberWithCommas(number) {
    // Convert the number to a string
    let numberString = number.toString();

    // Split the number into parts before and after the decimal point
    let parts = numberString.split(".");

    // Format the integer part with commas
    if (parts[0].length > 3) {
      // If the integer part has more than 3 digits, insert commas every two digits
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    // Join the parts back together
    let formattedNumber = parts.join(".");

    return formattedNumber;
  }
  useEffect(() => {
    const fetchBankDetails = async () => {
      try {
        const bankDetailsQuerySnapshot = await getDocs(
          query(
            collection(firestore, "BankDetails"),
            where("userId", "==", uid)
          )
        );

        if (bankDetailsQuerySnapshot.size > 0) {
          const bankData = bankDetailsQuerySnapshot.docs[0].data();
          setBankDetails(bankData);
          setUpiDetails(bankData.UPI);
        }
      } catch (error) {
        console.error("Error fetching bank details", error);
      }
    };

    async function fetchUserData() {
      try {
        const userData = await getUserData(uid); // Replace with your actual function
        setUserData(userData);
        // Now you can access userData.currentAmount here
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    }

    fetchUserData();
    fetchBankDetails();
  }, [uid]);

  const handlePayment = async () => {
    try {
      setLoading(true);

      // Validate amount
      const enteredAmount = parseFloat(amount);
      validateAmount(enteredAmount);

      // Check if bank details are filled
      if (withdrawalMethod === "bank") {
        checkBankDetails();
      }
      if (withdrawalMethod === "upi") {
        checkUpiDetails();
      }

      // Process withdrawal transaction
      await processWithdrawal(enteredAmount);

      toast.success("Withdrawal request successful!");
    } catch (error) {
      console.error("Error processing withdrawal", error);
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const validateAmount = (enteredAmount) => {
    if (isNaN(enteredAmount) || enteredAmount <= 0) {
      throw new Error("Please enter a valid amount.");
    }

    const minimumWithdrawalAmount = 100;
    const maximumWithdrawalAmount = 50000;
    if (enteredAmount < minimumWithdrawalAmount) {
      throw new Error(
        `Minimum withdrawal amount is ${minimumWithdrawalAmount}`
      );
    }
    if (enteredAmount > maximumWithdrawalAmount) {
      throw new Error(
        `Maximum withdrawal amount is ${maximumWithdrawalAmount}`
      );
    }
  };

  const checkBankDetails = () => {
    if (bankDetails.accountNo == "") {
      throw new Error(
        "Bank details not filled. Please fill your bank details first."
      );
    }
  };
  const checkUpiDetails = () => {
    if (bankDetails.upi == "") {
      throw new Error("Upi not filled. Please fill your bank details first.");
    }
  };

  const processWithdrawal = async (enteredAmount) => {
    // Retrieve user document
    const userDocSnapshot = await getDocs(
      query(collection(firestore, "Users"), where("userId", "==", uid))
    );

    if (userDocSnapshot.size > 0) {
      const userDoc = userDocSnapshot.docs[0].ref;
      const userData = userDocSnapshot.docs[0].data();

      // Check if withdrawal amount exceeds current balance
      if (enteredAmount > parseFloat(userData.currentAmount)) {
        throw new Error("Withdrawal amount exceeds current balance.");
      }

      // Create withdrawal transaction
      const withdrawalTransaction = {
        userId: uid,
        amount: enteredAmount,
        withdrawalMethod: withdrawalMethod,
        id: new Date().getTime(),
        timestamp: new Date(),
        bankName: bankDetails?.bankName,
        accountHolderName: bankDetails?.accountHolderName,
        accountNo: bankDetails?.accountNo,
        ifscCode: bankDetails?.IFSCCode,
        upiId: bankDetails?.UPI,
        type: "withdrawal",
        status: "pending",
      };

      // Add withdrawal transaction to the Transactions collection
      const withdrawalDocRef = await addDoc(
        collection(firestore, "Transactions"),
        withdrawalTransaction
      );

      // Update user document
      await updateDoc(userDoc, {
        totalWithdrawal: increment(enteredAmount),
        currentAmount: increment(-enteredAmount),
      });
    } else {
      throw new Error("User document not found.");
    }
  };

  return (
    <div className="max-w-md mx-auto p-4 rounded-md shadow-md">
      <Toaster toastOptions={{ duration: 2000 }} />
      <div className="mb-4">
        <span
          className="text-white cursor-pointer"
          onClick={() => navigate(-1)}
        >
          <FontAwesomeIcon icon={faArrowLeft} className="mr-2" />
          Back
        </span>
      </div>

      <hr />
      <h2 className="text-white text-lg mt-2">
        <FontAwesomeIcon
          icon={withdrawalMethod === "upi" ? faMoneyBill : faMoneyCheckAlt}
          className="mr-2"
        />
        Withdrawal
      </h2>
      <div className="bg-gray-800 flex items-center p-2 rounded my-2">
        <p className="font-poppins text-base text-white">Available Balance:</p>
        <p
          id="balance"
          className="ml-2 font-bold	font-poppins-bold text-base text-yellow-100"
        >
          ₹{" "}
          {formatIndianNumberWithCommas(
            parseFloat(userData?.currentAmount).toFixed(2)
          ) || 0.0}
        </p>
      </div>
      <div className="">
        <div className="mb-4">
          <label className="block text-sm font-medium text-white">
            Withdrawal Method:
          </label>
          <select
            value={withdrawalMethod}
            onChange={(e) => setWithdrawalMethod(e.target.value)}
            className="pl-4 bg-gray-700 h-12 text-white  outline-none  border-gray-300  mt-1 p-2 w-full border rounded-md focus:outline-none focus:ring focus:border-blue-300"
          >
            <option value="bank">Bank</option>
            <option value="upi">UPI</option>
          </select>
        </div>

        <div className="flex">
          {withdrawalMethod === "bank" && bankDetails?.accountNo ? (
            <div className="flex-1 bg-gray-800 rounded-md">
              <div className="mb-2 ">
                <label className="block p-2 text-sm font-medium text-white ">
                  Bank Details
                </label>
                <div className="ml-2 text-white">
                  Account Name:{" "}
                  <span className=" font-bold text-lg">
                    {" "}
                    {bankDetails.accountHolderName}
                  </span>
                </div>{" "}
                <div className="ml-2 text-white">
                  Bank Name:
                  <span className=" font-bold text-lg">
                    {" "}
                    {bankDetails.bankName}
                  </span>
                </div>
                <div className="ml-2 text-white">
                  Account No.:{" "}
                  <span className=" font-bold text-lg">
                    {" "}
                    {bankDetails.accountNo}
                  </span>
                </div>
                <div className="ml-2 text-white">
                  IFSC Code:{" "}
                  <span className=" font-bold text-lg">
                    {" "}
                    {bankDetails.IFSCCode}
                  </span>
                </div>
                <span className="ml-2 text-red-600">
                  *make sure your bank details are correct.
                </span>
              </div>
            </div>
          ) : withdrawalMethod === "bank" ? (
            <div className="flex-1 mr-4">
              <div className="mb-4">
                <p className="text-white">Bank details not found.</p>
                <p className="text-white">Please add bank details.</p>
                <Link to="/bank-details" className="text-blue-400">
                  {" "}
                  Add Bank Details
                </Link>
              </div>
            </div>
          ) : null}

          {withdrawalMethod === "upi" && upiDetails ? (
            <div className="flex-1">
              <div className="mb-4">
                <label className="block text-sm font-medium text-white">
                  UPI ID:
                </label>
                <div className="pl-4 bg-gray-700 h-12 text-white  outline-none  border-gray-300  mt-1 p-2 w-full border rounded-md focus:outline-none focus:ring focus:border-blue-300">
                  {upiDetails}
                </div>
              </div>
            </div>
          ) : withdrawalMethod === "upi" ? (
            <div className="flex-1">
              <div className="mb-4">
                <p className="text-white">UPI details not found.</p>
                <p className="text-white">Please add UPI details. </p>{" "}
                <Link to="/bank-details" className="text-blue-400">
                  {" "}
                  Add UPI Details
                </Link>
              </div>
            </div>
          ) : null}
        </div>

        <div>
          {(withdrawalMethod === "bank" && bankDetails?.accountNo) ||
          (withdrawalMethod === "upi" && upiDetails) ? (
            <>
              <label className="block text-sm font-medium mb-1 text-white">
                Amount:
              </label>
              <div className="mb-4 relative ">
                <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-white">
                  ₹
                </span>
                <input
                  type="number"
                  value={amount}
                  required
                  onChange={(e) => {
                    const enteredValue = e.target.value;
                    const maxValue = userData.currentAmount;

                    if (
                      enteredValue === "" ||
                      (!isNaN(enteredValue) &&
                        parseFloat(enteredValue) <= maxValue)
                    ) {
                      setAmount(e.target.value);
                    }
                  }}
                  className="pl-7 bg-gray-700 h-12 text-white  outline-none  border-gray-300  p-2 w-full border rounded-md focus:outline-none focus:ring focus:border-blue-300"
                />
              </div>
              <button
                onClick={handlePayment}
                disabled={loading}
                className={`bg-green-600 w-full flex gap-1 items-center justify-center py-2.5 text-white rounded ${
                  loading ? "opacity-50 cursor-not-allowed" : ""
                }`}
              >
                {loading && (
                  <CgSpinner size={20} className="mt-1 animate-spin" />
                )}
                <span>Withdrawal</span>
              </button>
            </>
          ) : (
            ""
          )}
        </div>
        {/* <div className="mt-2 text-white">
          <p>Withdrawal Conditions:-</p>
          <p>1. Minimum withdrawal amount is ₹100.</p>
          <p>2. Withdrawal amount processed within 8 hrs.</p>
          <p>3. Withdrawal allowed 24x7.</p>
        </div> */}
        <div className="mt-4 text-gray-300 text-sm">
          <p>
            <FontAwesomeIcon icon={faMoneyCheckAlt} className="mr-2" />
            Minimum withdrawal amount is ₹100.
          </p>
          <br />
          <p>
            <FontAwesomeIcon icon={faTimeline} className="mr-2" />
            Withdrawal amount processed within 8 hrs.
          </p>
          <br />
          <p>
            <FontAwesomeIcon icon={faShieldAlt} className="mr-2" />
            Your money is safe with us. We use secure payment gateways to
            process transactions.
          </p>
          <br />
          <p>
            <FontAwesomeIcon icon={faPhoneAlt} className="mr-2" />
            For any issues or concerns, please contact our support team.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Withdrawal;
