import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  collection,
  query,
  where,
  getDocs,
  setDoc,
  doc,
  updateDoc,
} from "firebase/firestore";
import { firestore } from "../firebase.config";
import { toast, Toaster } from "react-hot-toast";
import { CgSpinner } from "react-icons/cg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faPersonCircleCheck,
  faPersonRifle,
  faSave,
} from "@fortawesome/free-solid-svg-icons";
import {
  FaMailBulk,
  FaMailchimp,
  FaPhone,
  FaUser,
  FaVoicemail,
} from "react-icons/fa";

const AccountDetails = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(true);
  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [phone, setPhone] = useState("");
  const uid = localStorage.getItem("token");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const q = query(
          collection(firestore, "Users"),
          where("userId", "==", uid)
        );
        const querySnapshot = await getDocs(q);

        if (querySnapshot.size > 0) {
          const userData = querySnapshot.docs[0].data();
          setName(userData.name || "");
          setEmail(userData.email || "");
          setPhone(userData.phone || "");
        }

        setLoading(false);
      } catch (error) {
        console.error("Error fetching user data", error);
        setLoading(false);
      }
    };

    fetchUserData();
  }, [uid]);

  const handleNameChange = (event) => {
    setName(event.target.value);
    setNameError("");
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
    setEmailError("");
  };

  const handleSaveDetails = async () => {
    try {
      setLoading(true);

      // Check if the name and email are not empty
      if (!name.trim()) {
        throw new Error("Name is required");
      }

      if (!email.trim() || !/\S+@\S+\.\S+/.test(email)) {
        throw new Error("Please enter a valid email address");
      }

      const userQuery = query(
        collection(firestore, "Users"),
        where("userId", "==", uid)
      );

      const userSnapshot = await getDocs(userQuery);

      if (userSnapshot.size > 0) {
        const updateUserRef = doc(firestore, "Users", userSnapshot.docs[0].id);
        await updateDoc(updateUserRef, {
          name: name,
          email: email,
        });
        toast.success("Details updated successfully");
      } else {
        const newUserRef = doc(firestore, "Users");
        await setDoc(newUserRef, {
          userId: uid,
          name: name,
          email: email,
        });
        toast.success("Details saved successfully");
      }
    } catch (error) {
      if (error.message.includes("Name")) {
        setNameError(error.message);
      } else if (error.message.includes("email")) {
        setEmailError(error.message);
      } else {
        toast.error(error.message);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="max-w-md mx-auto p-4 rounded-md shadow-md">
      <Toaster toastOptions={{ duration: 2000 }} />

      <div className="mb-4">
        <span
          className="text-white cursor-pointer"
          onClick={() => navigate(-1)}
        >
          <FontAwesomeIcon icon={faArrowLeft} className="mr-2" />
          Back
        </span>
      </div>

      <hr />
      <h2 className="text-white text-lg my-4">
        <FontAwesomeIcon icon={faPersonCircleCheck} className="mr-2" />
        Profile Details
      </h2>
      <div className="">
        <label className="block text-sm font-medium text-white">Name:</label>
        <div className="mb-4 relative">
          <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-white">
            <FaUser />
          </span>
          <input
            type="text"
            value={name}
            required
            onChange={handleNameChange}
            placeholder="Enter Name"
            className={`pl-8 bg-gray-700 h-12 text-white  outline-none  border-gray-300  p-2 w-full border rounded-md focus:outline-none focus:ring focus:border-blue-300 ${
              nameError ? "border-red-500" : ""
            }`}
          />
          {nameError && <p className="text-red-500 text-sm">{nameError}</p>}
        </div>
        <label className="block text-sm font-medium text-white">Email:</label>
        <div className="mb-4 relative">
          <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-white">
            <FaMailBulk />
          </span>
          <input
            type="email"
            value={email}
            onChange={handleEmailChange}
            required
            placeholder="Enter Email"
            className={`pl-8 bg-gray-700 h-12 text-white  outline-none  border-gray-300  p-2 w-full border rounded-md focus:outline-none focus:ring focus:border-blue-300 ${
              emailError ? "border-red-500" : ""
            }`}
          />
        </div>{" "}
        <label className="block text-sm font-medium text-white">Email:</label>
        <div className="mb-4 relative">
          <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-white">
            <FaPhone />
          </span>
          <input
            type="number"
            value={phone.slice(2)}
            onChange={handleEmailChange}
            disabled
            required
            placeholder="Phone"
            className={`pl-9 bg-gray-700 h-12 text-white  outline-none  border-gray-300  p-2 w-full border rounded-md focus:outline-none focus:ring focus:border-blue-300 ${
              emailError ? "border-red-500" : ""
            }`}
          />
        </div>
        {emailError && <p className="text-red-500 text-sm">{emailError}</p>}
        <div>
          <button
            onClick={handleSaveDetails}
            disabled={loading}
            className={`bg-green-600 w-full flex gap-1 items-center justify-center py-2.5 text-white rounded ${
              loading ? "opacity-50 cursor-not-allowed" : ""
            }`}
          >
            {loading && <CgSpinner size={20} className="mt-1 animate-spin" />}
            <span>{loading ? "Saving..." : "Save Details"}</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default AccountDetails;
