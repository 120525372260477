import React, { useEffect, useState } from "react";
import { getAuth, signOut, onAuthStateChanged } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { auth, getUserData } from "../firebase.config";
import { toast, Toaster } from "react-hot-toast";
import Footer from "../Components/Footer";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import Dashboard from "./Dashboard";
import ImageGrid from "../Components/ImageGrid";
import { getDoc, doc, query, where } from "firebase/firestore";
import { firestore } from "../firebase.config";
import { Loading } from "../Components/Loading";
import Cookies from "js-cookie";
// Import necessary FontAwesome components
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faUniversity,
  faExchangeAlt,
  faFileAlt,
  faShieldAlt,
  faQuestionCircle,
} from "@fortawesome/free-solid-svg-icons";

const Account = ({ userId }) => {
  const navigate = useNavigate();
  const uid = localStorage.getItem("token");
  const [userData, setUserData] = useState(null);
  function formatIndianNumberWithCommas(number) {
    // Convert the number to a string
    let numberString = number.toString();

    // Split the number into parts before and after the decimal point
    let parts = numberString.split(".");

    // Format the integer part with commas
    if (parts[0].length > 3) {
      // If the integer part has more than 3 digits, insert commas every two digits
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    // Join the parts back together
    let formattedNumber = parts.join(".");

    return formattedNumber;
  }

  // Example usage:

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getUserData(uid);
        setUserData(data);
      } catch (error) {
        // Handle error
      }
    };

    fetchData();
  }, [userId]);

  const handleSignOut = async () => {
    await signOut(auth);
    localStorage.removeItem("_grecaptcha");
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    Cookies.remove("authToken");
    navigate("/login");
  };

  return (
    <>
      <section
        className="flex color  text-white flex-col "
        style={{
          padding: "",
          maxWidth: "480px",
          margin: "0 auto",
        }}
      >
        <div className="p-3">
          <Toaster toastOptions={{ duration: 2000 }} />
          {userData ? (
            <>
              <div className="font-bold items-center mb-4">
                Hi
                <span className="text-yellow-100 text-xl">
                  {"  " + userData.name + ","}
                </span>
                <br />
                <span className="float-left text-lg mr-2">Welcome to </span>
                <img
                  src={require("../Assets/logo.png")}
                  className=" ml-6 w-36"
                />
              </div>
              <hr />
              <div className=" w-full p-2 flex flex-col">
                <div className="">
                  <div>
                    <p className="text-white  font-bold">Available Balance</p>
                    {/* Replace the following line with the actual available balance */}
                    <p id="currentAmount" className="text-white text-xl  ">
                      ₹{" "}
                      {formatIndianNumberWithCommas(
                        parseFloat(userData.currentAmount).toFixed(2)
                      )}
                    </p>
                  </div>
                </div>
                <div className="w-full flex justify-between mt-4">
                  <div className="w-1/2">
                    <p className="text-white font-bold">Invest Amount</p>
                    {/* Replace the following line with the actual invest amount */}
                    <p id="totalDeposit" className="text-white text-xl">
                      ₹{" "}
                      {formatIndianNumberWithCommas(
                        userData.totalDeposit.toFixed(2)
                      )}
                    </p>
                  </div>
                  <div className="w-1/2">
                    <p className="text-white font-bold">Withdrawal Amount</p>
                    {/* Replace the following line with the actual withdrawal amount */}
                    <p id="totalWithdrawal" className="text-white text-xl">
                      ₹{" "}
                      {formatIndianNumberWithCommas(
                        parseFloat(userData.totalWithdrawal).toFixed(2)
                      )}
                    </p>
                  </div>
                </div>
              </div>
              <hr />

              <ImageGrid />
            </>
          ) : (
            <Loading />
          )}
        </div>

        {/* Navigation Bar */}
        <nav className="flex flex-col text-white p-3">
          <Link to="/account-details" className="m-2">
            <FontAwesomeIcon icon={faUser} className="mr-2" />
            Profile
          </Link>
          <hr />
          <Link to="/bank-details" className="m-2">
            <FontAwesomeIcon icon={faUniversity} className="mr-2" />
            Bank Details
          </Link>
          <hr />
          <Link to="/transactions" className="m-2">
            <FontAwesomeIcon icon={faExchangeAlt} className="mr-2" />
            Transactions
          </Link>
          <hr />
          <Link to="/terms-conditions" className="m-2">
            <FontAwesomeIcon icon={faFileAlt} className="mr-2" />
            Terms & Conditions
          </Link>
          <hr />
          <Link to="/privacy-policy" className="m-2">
            <FontAwesomeIcon icon={faShieldAlt} className="mr-2" />
            Privacy Policy
          </Link>
          <hr />
          <a
            href="https://t.me/tapcent_support"
            target="_blank"
            className="m-2"
          >
            <FontAwesomeIcon icon={faQuestionCircle} className="mr-2" />
            Customer Support
          </a>
          <hr />
          <button
            onClick={handleSignOut}
            className="m-2 bg-red-600 p-3 rounded-lg"
          >
            Sign Out
          </button>
        </nav>
      </section>
      <div className="mt-4">TapCent</div>
      <Footer />
    </>
  );
};

export default Account;
