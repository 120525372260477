import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  collection,
  query,
  where,
  getDocs,
  setDoc,
  doc,
  updateDoc,
} from "firebase/firestore";
import { firestore } from "../firebase.config";
import { toast, Toaster } from "react-hot-toast";
import { CgSpinner } from "react-icons/cg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faSave,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";

const BankDetails = () => {
  const [details, setDetails] = useState({
    accountHolderName: "",
    bankName: "",
    accountNo: "",
    IFSCCode: "",
    UPI: "",
  });
  const [loading, setLoading] = useState(true);
  const [selectedMethod, setSelectedMethod] = useState("upi");
  const uid = localStorage.getItem("token");
  const navigate = useNavigate();
  const ifscReg = /^[A-Za-z]{4}[0-9]{6,7}$/;
  const upiReg = /^[a-zA-Z0-9.\-_]{2,256}@[a-zA-Z]{2,64}$/;
  const accReg = /^[0-9]{9,18}$/;

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        const q = query(
          collection(firestore, "BankDetails"),
          where("userId", "==", uid)
        );
        const querySnapshot = await getDocs(q);

        if (querySnapshot.size > 0) {
          const userDetails = querySnapshot.docs[0].data();
          setDetails({
            accountHolderName: userDetails.accountHolderName || "",
            bankName: userDetails.bankName || "",
            accountNo: userDetails.accountNo || "",
            IFSCCode: userDetails.IFSCCode || "",
            UPI: userDetails.UPI || "",
          });
        }

        setLoading(false);
      } catch (error) {
        console.error("Error fetching details", error);
        setLoading(false);
      }
    };

    fetchDetails();
  }, [uid]);

  const handleMethodChange = (event) => {
    setSelectedMethod(event.target.value);
  };

  const handleInputChange = (field, value) => {
    setDetails((prevDetails) => ({
      ...prevDetails,
      [field]: value,
    }));
  };

  const validateIFSC = (ifsc) => {
    return ifscReg.test(ifsc);
  };

  const validateAccountNo = (accountNo) => {
    return accReg.test(accountNo);
  };

  const validateUPI = (upi) => {
    return upiReg.test(upi);
  };

  const handleSaveDetails = async () => {
    try {
      setLoading(true);

      const requiredFields =
        selectedMethod === "bank"
          ? ["accountHolderName", "bankName", "accountNo", "IFSCCode"]
          : ["UPI"];

      if (requiredFields.some((field) => !details[field])) {
        throw new Error("All fields are required");
      }

      if (!uid) {
        throw new Error("User ID not available");
      }

      const userQuery = query(
        collection(firestore, "BankDetails"),
        where("userId", "==", uid)
      );

      const userSnapshot = await getDocs(userQuery);

      if (userSnapshot.size > 0) {
        const updateUserRef = doc(
          firestore,
          "BankDetails",
          userSnapshot.docs[0].id
        );

        // Validate IFSC code
        if (details.IFSCCode && !validateIFSC(details.IFSCCode)) {
          throw new Error("Invalid IFSC code");
        }

        // Validate account number
        if (details.accountNo && !validateAccountNo(details.accountNo)) {
          throw new Error("Invalid account number");
        }

        // Validate UPI
        if (details.UPI && !validateUPI(details.UPI)) {
          throw new Error("Invalid UPI");
        }

        await updateDoc(updateUserRef, {
          ...details,
        });
        toast.success("Details updated successfully");
      } else {
        const newUserRef = doc(firestore, "BankDetails", uid);

        // Validate IFSC code
        if (details.IFSCCode && !validateIFSC(details.IFSCCode)) {
          throw new Error("Invalid IFSC code");
        }

        // Validate account number
        if (details.accountNo && !validateAccountNo(details.accountNo)) {
          throw new Error("Invalid account number");
        }

        // Validate UPI
        if (details.UPI && !validateUPI(details.UPI)) {
          throw new Error("Invalid UPI");
        }

        await setDoc(newUserRef, {
          userId: uid,
          ...details,
        });
        toast.success("Bank Details Saved.");
      }
    } catch (error) {
      toast.error(error.message);
      console.error("Error saving details", error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="max-w-md mx-auto p-4 rounded-md shadow-md">
      <Toaster toastOptions={{ duration: 2000 }} />
      <div className="mb-4">
        <span
          className="text-white cursor-pointer"
          onClick={() => navigate(-1)}
        >
          <FontAwesomeIcon icon={faArrowLeft} className="mr-2" />
          Back
        </span>
      </div>

      <hr />
      <h2 className="text-white text-lg my-4">
        <FontAwesomeIcon icon={faSave} className="mr-2" />
        Details
      </h2>
      <div className="">
        <div className="mb-4 ">
          <label className="block text-sm font-medium text-white">
            Withdrawal Details Method:
          </label>
          <select
            value={selectedMethod}
            onChange={handleMethodChange}
            className="pl-7 bg-gray-700 h-12 text-white  outline-none  border-gray-300  mt-1 p-2 w-full border rounded-md focus:outline-none focus:ring focus:border-blue-300"
          >
            <option value="bank">Bank</option>
            <option value="upi">UPI</option>
          </select>
        </div>
        {selectedMethod === "bank" ? (
          <>
            <div className="mb-4 ">
              <label className="block text-sm font-medium text-white">
                Account Holder Name:
              </label>
              <input
                type="text"
                value={details.accountHolderName}
                required
                onChange={(e) =>
                  handleInputChange("accountHolderName", e.target.value)
                }
                className="pl-7 bg-gray-700 h-12 text-white  outline-none  border-gray-300  mt-1 p-2 w-full border rounded-md focus:outline-none focus:ring focus:border-blue-300"
              />
            </div>
            <div className="mb-4 ">
              <label className="block text-sm font-medium text-white">
                Bank Name:
              </label>
              <input
                type="text"
                value={details.bankName}
                required
                onChange={(e) => handleInputChange("bankName", e.target.value)}
                className="pl-7 bg-gray-700 h-12 text-white  outline-none  border-gray-300  mt-1 p-2 w-full border rounded-md focus:outline-none focus:ring focus:border-blue-300"
              />
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-white">
                Account No:
              </label>
              <input
                type="number"
                value={details.accountNo}
                required
                onChange={(e) => handleInputChange("accountNo", e.target.value)}
                className="pl-7 bg-gray-700 h-12 text-white  outline-none  border-gray-300  mt-1 p-2 w-full border rounded-md focus:outline-none focus:ring focus:border-blue-300"
              />
              <small className="text-gray-500">
                <FontAwesomeIcon icon={faInfoCircle} className="mr-1" />
                Enter a valid account number
              </small>
            </div>
            <div className="mb-4 ">
              <label className="block text-sm font-medium text-white">
                IFSC Code:
              </label>
              <input
                type="text"
                value={details.IFSCCode}
                required
                onChange={(e) => handleInputChange("IFSCCode", e.target.value)}
                className="pl-7 bg-gray-700 h-12 text-white  outline-none  border-gray-300  mt-1 p-2 w-full border rounded-md focus:outline-none focus:ring focus:border-blue-300"
              />
              <small className="text-gray-500">
                <FontAwesomeIcon icon={faInfoCircle} className="mr-1" />
                Enter a valid IFSC code
              </small>
            </div>
          </>
        ) : (
          <>
            <div className=" relative mb-4">
              <label className="block text-sm font-medium text-white">
                UPI:
              </label>

              <input
                type="text"
                value={details.UPI}
                required
                onChange={(e) => handleInputChange("UPI", e.target.value)}
                className="pl-7 bg-gray-700 h-12 text-white  outline-none  border-gray-300  mt-1 p-2 w-full border rounded-md focus:outline-none focus:ring focus:border-blue-300"
              />
              <small className="text-gray-500">
                <FontAwesomeIcon icon={faInfoCircle} className="mr-1" />
                Enter a valid UPI
              </small>
            </div>
          </>
        )}
        <div>
          <button
            onClick={handleSaveDetails}
            disabled={loading}
            className={`bg-green-600 w-full flex gap-1 items-center justify-center py-2.5 text-white rounded ${
              loading ? "opacity-50 cursor-not-allowed" : ""
            }`}
          >
            {loading && <CgSpinner size={20} className="mt-1 animate-spin" />}
            <span>Save Details</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default BankDetails;
