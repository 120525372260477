// ImageGrid component
import React from "react";
import { Link } from "react-router-dom";

const ImageGrid = () => {
  return (
    <div className="grid grid-cols-4 mt-4 gap-4">
      <div className="flex flex-col items-center justify-center text-center">
        <Link to="/deposit">
          <img
            className="h-auto ml-1 max-w-full w-12 rounded-lg flex items-center" // Add flex and items-center here
            src={require("../Assets/wallet.png")}
            alt=""
          />
          <p className="mt-2">Deposit</p>
        </Link>
      </div>
      <div className="flex  flex-col items-center justify-center text-center">
        <Link to="/withdrawal">
          <img
            className="h-auto ml-5 max-w-full w-12 rounded-lg flex items-center" // Add flex and items-center here
            src={require("../Assets/withdrawal.png")}
            alt=""
          />
          <p className="mt-2">Withdrawal</p>
        </Link>
      </div>
      <div className="flex flex-col items-center justify-center text-center">
        <Link to="/referral">
          <img
            className="h-auto ml-1 max-w-full w-12 rounded-lg flex items-center" // Add flex and items-center here
            src={require("../Assets/refer.png")}
            alt=""
          />
          <p className="mt-2">Referral</p>
        </Link>
      </div>
      <div className="flex flex-col items-center justify-center text-center">
        <a href="https://t.me/OfficialTapCent" target="_blank">
          <img
            className="h-auto ml-2 max-w-full w-12 rounded-lg flex items-center" // Add flex and items-center here
            src={require("../Assets/telegram.png")}
            alt=""
          />
          <p className="mt-2">Telegram</p>
        </a>
      </div>
    </div>

    // <div className="text-sm justify-center w-full items-center grid mt-4 grid-cols-4 md:grid-cols-4 gap-4">
    //   <div className=" items-center">
    //     <Link to="/deposit">
    //       <img
    //         className="h-auto max-w-full w-12 rounded-lg"
    //         src={require("../Assets/wallet.png")}
    //         alt=""
    //       />
    //       <p className="mt-2">Deposit</p>
    //     </Link>
    //   </div>
    //   <div className="items-center">
    //     <Link to="/withdrawal">
    //       <img
    //         className="h-auto max-w-full w-12 rounded-lg"
    //         src={require("../Assets/withdrawal.png")}
    //         alt=""
    //       />
    //       <p className="mt-2">Withdraw</p>
    //     </Link>
    //   </div>
    //   <div className="items-center">
    //     <Link to="/referral">
    //       <img
    //         className="h-auto max-w-full w-12 rounded-lg"
    //         src={require("../Assets/refer.png")}
    //         alt=""
    //       />
    //       <p className="mt-2">Referral</p>
    //     </Link>
    //   </div>
    //   <div className=" items-center">
    //     <Link to="/telegram">
    //       <img
    //         className="h-auto max-w-full w-12 rounded-lg"
    //         src={require("../Assets/telegram.png")}
    //         alt=""
    //       />
    //       <p className="mt-2">Telegram</p>
    //     </Link>
    //   </div>
    // </div>
  );
};

export default ImageGrid;
