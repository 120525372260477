import React, { useState } from "react";
import { FaMoneyCheckAlt, FaMoneyBill } from "react-icons/fa";

const TransactionList = ({ transactions }) => {
  const [selectedFilter, setSelectedFilter] = useState("all");

  function formatIndianNumberWithCommas(number) {
    // Convert the number to a string
    let numberString = number.toString();

    // Split the number into parts before and after the decimal point
    let parts = numberString.split(".");

    // Format the integer part with commas
    if (parts[0].length > 3) {
      // If the integer part has more than 3 digits, insert commas every two digits
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    // Join the parts back together
    let formattedNumber = parts.join(".");

    return formattedNumber;
  }

  const filteredTransactions =
    selectedFilter === "all"
      ? transactions
      : transactions.filter(
          (transaction) => transaction.transactionType === selectedFilter
        );

  return (
    <div className="">
      <h2 className="text-lg font-bold mb-2">Transaction History</h2>
      <div className="mb-2">
        <label className="text-md font-medium">Filter: </label>
        <select
          value={selectedFilter}
          onChange={(e) => setSelectedFilter(e.target.value)}
          className="bg-gray-700 text-white p-2 rounded-md"
        >
          <option value="all">All</option>
          <option value="deposit">Invest</option>
          <option value="withdrawal">Withdrawal</option>
          <option value="interest">Interest</option>
        </select>
      </div>
      {filteredTransactions.map((transaction) => (
        <div key={transaction.id} className="border-b p-2 border-gray-300">
          <div className="flex items-center mb-2">
            {transaction.transactionType === "withdrawal" ? (
              <FaMoneyBill className="text-red-500 mr-2" />
            ) : transaction.transactionType === "deposit" ? (
              <FaMoneyCheckAlt className="text-green-500 mr-2" />
            ) : (
              <FaMoneyCheckAlt className="text-blue-500 mr-2" />
            )}
            <p className="text-md font-medium">
              {transaction.transactionType === "withdrawal"
                ? "Withdrawal"
                : transaction.transactionType === "deposit"
                ? "Invest"
                : "Interest"}
            </p>
          </div>
          <p className="text-md font-medium">
            <span className="text-green-400">Amount:</span> ₹
            {formatIndianNumberWithCommas(transaction.amount.toFixed(2))}
            {transaction.transactionType === "interest" &&
            transaction.interest !== undefined
              ? "(~" + transaction?.interest + "%)"
              : ""}
          </p>

          <p className="text-md font-medium">
            <span className="text-green-400">Date: </span>
            {transaction.transactionDate.toDate().toLocaleDateString() + ","}
            {transaction.transactionDate.toDate().toLocaleTimeString()}
          </p>
        </div>
      ))}
    </div>
  );
};

export default TransactionList;
