import React, { useEffect, useState } from "react";
import { AiOutlineCheckCircle, AiFillHome } from "react-icons/ai";
import { firestore, getInvestData, getUserData } from "../firebase.config";
import toast from "react-hot-toast";
import {
  doc,
  setDoc,
  getDoc,
  collection,
  increment as firebaseIncrement,
  addDoc,
} from "firebase/firestore";
import styled from "styled-components";

const ProgressBarWrapper = styled.div`
  --percentage: var(--value);
  --primary: #46a83b;
  --secondary: #adf;
  --size: 100%;
  animation: progress 2s 0.5s forwards;
  width: var(--size);
  aspect-ratio: 2 / 1;
  border-radius: 50% / 100% 100% 0 0;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: flex-end;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: conic-gradient(
      from 0.75turn at 50% 100%,
      var(--primary) calc(var(--percentage) * 1% / 2),
      var(--secondary) calc(var(--percentage) * 1% / 2 + 0.1%)
    );
    mask: radial-gradient(at 50% 100%, white 55%, transparent 55.5%);
    mask-mode: alpha;
    -webkit-mask: radial-gradient(at 50% 100%, #0000 55%, #000 55.5%);
    -webkit-mask-mode: alpha;
  }
`;
const Investment = ({ userId }) => {
  const [loading, setLoading] = useState(false);
  const [investmentAmount, setInvestmentAmount] = useState("");
  const [interestRate, setInterestRate] = useState(""); // Add state for interestRate

  const uid = localStorage.getItem("token");
  const [calcuInterest, setCalcuInterest] = useState(0);

  const [userData, setUserData] = useState(null);
  const [fetchInvest, setFetchInvest] = useState("");
  function formatIndianNumberWithCommas(number) {
    // Convert the number to a string
    let numberString = number.toString();

    // Split the number into parts before and after the decimal point
    let parts = numberString.split(".");

    // Format the integer part with commas
    if (parts[0].length > 3) {
      // If the integer part has more than 3 digits, insert commas every two digits
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    // Join the parts back together
    let formattedNumber = parts.join(".");

    return formattedNumber;
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getUserData(uid);
        const investData = await getInvestData(uid, uid);
        setUserData(data);
        setFetchInvest(investData);
      } catch (error) {
        console.error("Failed to fetch user data:", error);
        toast.error("Contact administrator");
      }
    };
    const fetchInterestRate = async () => {
      const docRef = doc(firestore, "Data", "Interest");
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        // console.log("Document data:", docSnap.data());
        setInterestRate(docSnap.data().interestRate);
      } else {
        // docSnap.data() will be undefined in this case
        console.log("No such document!");
      }
    };

    fetchData();
    fetchInterestRate();
  }, [userId]);

  useEffect(() => {
    if (!isNaN(investmentAmount) && parseFloat(investmentAmount) > 0) {
      const dailyInterestRate = interestRate;
      const interest = (parseFloat(investmentAmount) * dailyInterestRate) / 100;
      setCalcuInterest(interest);
    }
  }, [investmentAmount]);

  const handlePayNow = async () => {
    try {
      setLoading(true);

      const amount = parseFloat(investmentAmount);
      if (isNaN(amount) || amount <= 0) {
        toast.error("Please enter a valid investment amount.");
        return;
      }

      if (amount < 500) {
        toast.error("Minimum ₹ 500 Investment amount.");
        return;
      }

      const dailyInterestRate = 2;
      const interest = (amount * dailyInterestRate) / 24;
      const totalAmountAfterInterest = amount + interest;

      if (amount > userData.currentAmount) {
        toast.error("Investment amount exceeds available balance.");
        return;
      }

      const investmentDocRef = doc(firestore, "Investments", uid);
      const investmentDocSnap = await getDoc(investmentDocRef);

      if (investmentDocSnap.exists()) {
        await setDoc(
          investmentDocRef,
          {
            amount: firebaseIncrement(amount),
          },
          { merge: true }
        );
      } else {
        const investData = {
          amount,
          interest: dailyInterestRate,
          investId: new Date().getTime(),
          status: "active",
          time: new Date(),
          transactionType: "investment",
          userId: uid,
          collect: 0,
        };

        await setDoc(investmentDocRef, investData);
      }

      const updatedCurrentAmount = userData.currentAmount - amount;
      const updatedInvestAmount = fetchInvest.amount + amount;

      const userDocRef = doc(firestore, "Users", uid);
      await setDoc(
        userDocRef,
        { currentAmount: updatedCurrentAmount },
        { merge: true }
      );

      setUserData((prevUserData) => ({
        ...prevUserData,
        currentAmount: updatedCurrentAmount,
      }));

      setFetchInvest((prevInvestData) => ({
        ...prevInvestData,
        amount: updatedInvestAmount,
      }));

      const transactionData = {
        userId: fetchInvest.userId,
        investId: fetchInvest.investId,
        transactionType: "deposit",
        amount: amount,
        transactionDate: new Date(),
      };

      await addDoc(
        collection(firestore, "InvestTransactions"),
        transactionData
      );

      toast.success("Investment successful!");
    } catch (error) {
      console.error("Error processing investment:", error);
      toast.error("An error occurred. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex flex-col items-center justify-center ">
      <div className="w-full ">
        <div className="">
          <div className="bg-gray-800 flex items-center p-2 rounded">
            <p className="font-poppins text-base text-white">
              Available Balance:
            </p>
            <p
              id="balance"
              className="ml-2 font-bold	font-poppins-bold text-base text-yellow-100"
            >
              ₹{" "}
              {formatIndianNumberWithCommas(
                parseFloat(userData?.currentAmount).toFixed(2)
              ) || 0.0}
            </p>
          </div>
          <div className="flex flex-col items-center justify-center ">
            <div className="w-full ">
              <div className="p-4 justify-center items-center text-center">
                <ProgressBarWrapper
                  style={{
                    "--value":
                      fetchInvest.amount < 100000
                        ? fetchInvest.amount / 1000 || 0.0
                        : fetchInvest.amount / 10000,
                  }}
                >
                  {" "}
                  <div className="flex items-center justify-center  w-full h-full">
                    <p className="text-green-500 font-bold	 text-center mt-10">
                      Interest Earning
                      <br />₹{" "}
                      {formatIndianNumberWithCommas(
                        parseFloat(fetchInvest?.collect).toFixed(2)
                      ) || 0.0}
                      /-
                    </p>
                  </div>
                </ProgressBarWrapper>
              </div>
              <p className="font-bold	 float-left">
                Invested Amount <br />₹{" "}
                {formatIndianNumberWithCommas(
                  parseFloat(fetchInvest?.amount).toFixed(2)
                ) || 0.0}
              </p>
              <p className="text-right font-bold	 float-right ">
                Remaining Amount <br />₹{" "}
                {fetchInvest.amount < 100000
                  ? formatIndianNumberWithCommas(
                      parseFloat(100000 - fetchInvest?.amount).toFixed(2)
                    ) || 0.0
                  : formatIndianNumberWithCommas(
                      parseFloat(1000000 - fetchInvest?.amount).toFixed(2)
                    ) || 0.0}
              </p>
            </div>
            <div className="flex items-center mt-4">
              <p className="font-poppins text-base text-white">
                Interest/Day:{" "}
              </p>
              <p
                id="interestRate"
                className="ml-2 font-bold	font-poppins-bold text-base text-green-600"
              >
                {/*  {console.log(interestRate)} */}₹
                {formatIndianNumberWithCommas(
                  parseFloat(
                    (fetchInvest?.amount * interestRate) / 100
                  ).toFixed(2)
                ) || 0.0}{" "}
                (~{interestRate}%)
              </p>
            </div>
          </div>
          <p className="">Invest:</p>
          <div className="relative ">
            <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-white">
              ₹
            </span>
            <input
              id="enterAmount"
              type="number"
              value={investmentAmount}
              onChange={(e) => {
                const enteredValue = e.target.value;
                const maxValue = userData?.currentAmount;

                if (
                  enteredValue === "" ||
                  (!isNaN(enteredValue) && parseFloat(enteredValue) <= maxValue)
                ) {
                  setInvestmentAmount(enteredValue);
                }
              }}
              className="pl-7 bg-gray-700 h-12 w-full text-white  outline-none border border-gray-300 rounded"
              placeholder="Enter Amount"
            />
          </div>
          <div className="flex items-center mt-2">
            <p className="font-poppins text-base text-white">
              Get Interest/Day:{" "}
            </p>

            <p
              id="interestRate"
              className="ml-2 font-poppins-bold text-base text-green-600"
            >
              ₹ {formatIndianNumberWithCommas(calcuInterest.toFixed(2))}
            </p>
          </div>
          <div className="flex items-center mt-2">
            <div className="w-full">
              <button
                id="pay"
                onClick={handlePayNow}
                disabled={loading}
                className={`bg-green-600 w-full flex gap-1 items-center justify-center py-2.5 text-white rounded ${
                  loading ? "opacity-50 cursor-not-allowed" : ""
                }`}
              >
                {loading ? "Processing..." : "Invest More"}
              </button>
              <div className="flex items-center mt-4">
                <AiOutlineCheckCircle className="w-5 h-5 text-green-600" />
                <p
                  id="textView12"
                  className="ml-2 font-poppins-bold text-base text-green-600"
                >
                  Your Money is 100% safe with us.
                </p>
              </div>
            </div>
          </div>
          <p
            id="interest"
            className="mt-1 font-poppins text-base text-green-200"
          >
            * Get Upto 10% per day*
          </p>{" "}
          <p className="font-poppins mt-2 text-base text-green-200">
            * Interest will be auto-credit in your wallet at the end of the day.{" "}
          </p>{" "}
        </div>
      </div>
    </div>
  );
};

export default Investment;
