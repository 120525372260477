import React, { useEffect, useState } from "react";
import { signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { getUserData, auth } from "../firebase.config";
import { toast, Toaster } from "react-hot-toast";
import Footer from "../Components/Footer";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import { Loading } from "../Components/Loading";
import Investment from "./Investment";
import Account from "./Account";
import Portfolio from "./Portfolio";

const Dashboard = ({ userId }) => {
  const navigate = useNavigate();
  const uid = localStorage.getItem("token");
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getUserData(uid);
        setUserData(data);
      } catch (error) {
        // Handle error
      }
    };

    fetchData();
  }, [userId]);

  return (
    <>
      <section
        className="flex color text-white flex-col "
        style={{
          padding: "",
          maxWidth: "480px",
          margin: "0 auto",
        }}
      >
        <div className="p-5">
          <Toaster toastOptions={{ duration: 2000 }} />
          {userData ? (
            <>
              <div className="font-bold mb-2 items-center">
                Welcome, <br />
                <span className="text-yellow-100 text-3xl">
                  {userData.name}
                </span>
              </div>
              <hr />
              <Investment />
            </>
          ) : (
            <Loading />
          )}
        </div>
      </section>
      <div className="mt-4">TapCent</div>
      <Footer />
    </>
  );
};

export default Dashboard;
