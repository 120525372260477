import React, { useEffect, useState } from "react";
import copy from "clipboard-copy";
import { FaCopy, FaWhatsapp, FaTelegram } from "react-icons/fa";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { collection, getDocs, query, where } from "firebase/firestore";
import { firestore } from "../firebase.config";

const Referral = () => {
  const [referralCount, setReferralCount] = useState(0);
  const [referredUsers, setReferredUsers] = useState([]);
  const referralLink = `https://tapcent.in/login?refCode=${localStorage
    .getItem("token")
    .substring(0, 10)}`;

  const navigate = useNavigate();

  useEffect(() => {
    // Fetch the number of referred users
    const fetchReferralData = async () => {
      try {
        const referralQuerySnapshot = await getDocs(
          query(
            collection(firestore, "Users"),
            where(
              "referUid",
              "==",
              localStorage.getItem("token").substring(0, 10)
            )
          )
        );
        const usersData = referralQuerySnapshot.docs.map((doc) => ({
          id: doc.id,
          phone: doc.data().phone,
          totalDeposit: doc.data().totalDeposit,
        }));
        setReferredUsers(usersData);
        setReferralCount(usersData.length);
      } catch (error) {
        console.error("Error fetching referral data:", error);
      }
    };

    fetchReferralData();
  }, []);

  const copyToClipboard = async () => {
    try {
      await copy(referralLink);
      toast.success("Link copied to clipboard!");
    } catch (error) {
      console.error("Error copying link to clipboard", error);
    }
  };

  // Calculate total earnings from referred users
  const totalEarnings = referredUsers.reduce(
    (total, user) => total + user.totalDeposit * 0.01,
    0
  );

  return (
    <div className="h-full text-white p-4 shadow-md">
      <Toaster toastOptions={{ duration: 2000 }} />
      <div className="mb-4">
        <span
          className="text-white cursor-pointer"
          onClick={() => navigate(-1)}
        >
          <FontAwesomeIcon icon={faArrowLeft} className="mr-2" />
          Back
        </span>
      </div>

      <h1 className="text-3xl font-bold mb-4">Referral Program</h1>

      <div className="mb-6">
        <p className="text-lg">
          Share your unique referral link and earn 1% of every deposit made by
          your friends!
        </p>
      </div>

      <div className="mb-4 items-center">
        <p className="text-lg mr-2">Your unique referral link:</p>
        <div className="flex items-center">
          <input
            type="text"
            className="bg-gray-700 text-white p-2 w-full rounded-md"
            value={referralLink}
            readOnly
          />
          <button
            onClick={copyToClipboard}
            className="ml-2 w-52 bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-md flex items-center transition duration-300"
          >
            <FaCopy className="mr-2" />
            Copy Link
          </button>
        </div>
      </div>

      <div className="mb-4">
        <p className="text-lg">Share this link with your friends:</p>
        <div className="flex mt-2">
          <a
            href={`https://api.whatsapp.com/send?text=${encodeURIComponent(
              referralLink
            )}`}
            target="_blank"
            rel="noopener noreferrer"
            className="bg-green-500 ml-1  hover:bg-green-600 text-white px-4 py-2 rounded-md flex items-center transition duration-300"
          >
            <FaWhatsapp className="mr-2" />
            Share on WhatsApp
          </a>
          <a
            href={`https://t.me/share/url?url=${encodeURIComponent(
              referralLink
            )}`}
            target="_blank"
            rel="noopener noreferrer"
            className="bg-blue-500 ml-1 hover-bg-blue-600 text-white px-4 py-2 rounded-md flex items-center transition duration-300"
          >
            <FaTelegram className="mr-2" />
            Share on Telegram
          </a>
        </div>
      </div>

      <div className="mb-4 ">
        <p className="text-lg text-green-500">
          Number of Referred Users: {referralCount}
        </p>
      </div>

      <div className="mb-4">
        <p className="text-lg bg-gray-600 rounded-lg p-4">
          Total Earnings from Referrals: ₹{totalEarnings.toFixed(2)}
        </p>
      </div>

      <div>
        <h2 className="text-2xl font-bold mb-2">List of Referred Users</h2>
        <table className="w-full">
          <thead>
            <tr>
              <th className="py-2">Phone (Last 4 Digits)</th>
              <th className="py-2">Earnings through Referral</th>
            </tr>
          </thead>
          <tbody className=" text-center">
            {referredUsers.map((user) => (
              <tr key={user.id}>
                <td className="py-2">+91******{user.phone.slice(-4)}</td>
                <td className="py-2">
                  ₹{(user.totalDeposit * 0.01).toFixed(2)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Referral;
