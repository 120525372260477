import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";

function App() {
  return (
    <div
      className="App bg-gray-950  h-screen"
      style={{
        fontFamily: "Poppins, sans-serif",
        padding: "",
        backgroundColor: "rgb(3 7 18 / var(--tw-bg-opacity))",
        maxWidth: "480px",
        margin: "0 auto",
      }}
    >
      <Outlet />
    </div>
  );
}

export default App;
