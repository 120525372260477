import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  collection,
  doc,
  updateDoc,
  addDoc,
  increment,
  getDocs,
  query,
  where,
  getDoc,
} from "firebase/firestore";
import { firestore, getUserData } from "../firebase.config";
import { toast, Toaster } from "react-hot-toast";
import { CgSpinner } from "react-icons/cg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhoneAlt, faShieldAlt } from "@fortawesome/free-solid-svg-icons";
import QRCode, { QRCodeCanvas } from "qrcode.react";

const Deposit = () => {
  const [amount, setAmount] = useState("");
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState(null);
  const [upiId, setUpiId] = useState("");
  const [depositMethod, setDepositMethod] = useState("bank");
  const uid = localStorage.getItem("token");
  const upiLink = `upi://pay?pa=rpy.tapcent2takainterest@icici&pn=TapCent&tn=investment&am=${amount}`;
  const paymentMethods = [
    {
      id: "phonepe",
      name: "PhonePe",
      image: require("../Assets/phonepe.png"),
      url: `phonepe://pay?pa=rpy.tapcent2takainterest@icici&pn=TapCent&tn=investment&am=${amount}`,
    },
    {
      id: "paytm",
      name: "Paytm",
      image: require("../Assets/paytm.png"),
      url: `paytmmp://pay?pa=rpy.tapcent2takainterest@icici&pn=TapCent&tn=investment&am=${amount}`,
    },
    {
      id: "gpay",
      name: "GPay",
      image: require("../Assets/gpay.png"),
      url: `tez://upi/pay?pa=rpy.tapcent2takainterest@icici&pn=TapCent&tn=investment&am=${amount}`,
    },
    {
      id: "upi",
      name: "UPi",
      image: require("../Assets/upi.png"),
      url: `upi://pay?pa=rpy.tapcent2takainterest@icici&pn=TapCent&tn=investment&am=${amount}`,
    },
    // Add more payment methods as needed
  ];
  const validateUPI = (upiId) => {
    // Assuming you have a regular expression for UPI validation
    const upiReg = /^[a-zA-Z0-9.\-_]{2,256}@[a-zA-Z]{2,64}$/;

    return upiReg.test(upiId);
  };
  const navigate = useNavigate();
  const toggleDepositMethod = (method) => {
    setDepositMethod(method);
  };

  useEffect(() => {
    const fetchData = async () => {
      const data = await getUserData(uid);
      setUserData(data);
    };

    fetchData();
  }, [uid]);

  const loadRazorpayScript = async () => {
    return new Promise((resolve, reject) => {
      const script = document.createElement("script");
      script.src = "https://checkout.razorpay.com/v1/checkout.js";
      script.onload = resolve;
      script.onerror = reject;
      document.body.appendChild(script);
    });
  };

  const handleCardPayment = async () => {
    setLoading(true);

    if (isNaN(amount) || amount <= 0) {
      toast.error("Please enter a valid Deposit amount.");
      setLoading(false);
      return;
    }

    if (amount < 500) {
      toast.error("Minimum ₹ 500 Investment amount.");
      setLoading(false);

      return;
    }

    if (amount > 100000) {
      toast.error("Maximum ₹ 1,00,000 Deposit amount.");
      setLoading(false);

      return;
    }

    try {
      await loadRazorpayScript();

      // Create a transaction in Firestore
      const transactionRef = await addDoc(
        collection(firestore, "Transactions"),
        {
          userId: uid,
          amount: parseFloat(amount),
          status: "pending",
          timestamp: new Date(),
          type: "deposit",
          id: new Date().getTime(),
          depositMethod: "Card",
          razorpay_payment_id: "",
        }
      );

      const transactionId = transactionRef.id;

      // Initialize Razorpay
      const rzp = new window.Razorpay({
        key: "rzp_live_vd04YMzkdNhbTG",
        amount: amount * 100,
        currency: "INR",
        name: "TapCent",
        description: "Deposit",
        image: `${require("../Assets/logo.png")}`,
        order_id: "",
        options: {
          checkout: {
            name: "TapCent",
          },
        },
        config: {
          display: {
            hide: [
              { method: "upi" },
              { method: "paylater" },
              { method: "emi" },
              { method: "netbanking" },
              { method: "wallet" },
            ],
            preferences: {
              show_default_blocks: "true",
            },
          },
        },
        handler: async (response) => {
          setLoading(true);
          // Handle the payment success

          await updateDoc(doc(firestore, "Transactions", transactionId), {
            status: "success",
            razorpay_payment_id: response.razorpay_payment_id,
          });

          const userDocRef = doc(firestore, "Users", uid);

          // Update the user document
          await updateDoc(userDocRef, {
            currentAmount: increment(parseFloat(amount)),
            totalDeposit: increment(parseFloat(amount)),
          });

          if (userData?.referUid) {
            // Extract the referUid
            const referUid = userData.referUid;

            if (referUid.length > 0) {
              //console.log(referUid);

              const q = query(
                collection(firestore, "Users"),
                where("userId", ">=", referUid),
                where("userId", "<=", referUid + "\uf8ff") // '\uf8ff' is a high surrogate character
              );
              try {
                const querySnapshot = await getDocs(q);
                //console.log(querySnapshot);
                if (!querySnapshot.empty) {
                  querySnapshot.forEach((referredUserDoc) => {
                    //  console.log(referredUserDoc.data());
                    const referralBonus = parseFloat(amount) * 0.01;
                    updateDoc(referredUserDoc.ref, {
                      currentAmount: increment(referralBonus),
                      referBonus: increment(referralBonus),
                    });
                  });
                } else {
                  console.log("No matching user found");
                }
              } catch (error) {
                console.error("Error querying or updating documents:", error);
              }
            } else {
              console.log("ReferUid is too short");
            }
          }

          toast.success("Payment successful!");
          setLoading(false);
        },
        prefill: {
          name: userData.name,
          email: "airxstudio@gmail.com",
          contact: "+" + userData.phone,
        },
        notes: {
          phone: userData.phone,
        },
        theme: {
          color: "#030712",
        },
      });

      rzp.open();
    } catch (error) {
      console.error("Error processing payment", error);
      toast.error("Error processing payment. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleBankPayment = async () => {
    setLoading(true);

    if (isNaN(amount) || amount <= 0) {
      toast.error("Please enter a valid Deposit amount.");
      setLoading(false);
      return;
    }

    if (amount < 500) {
      toast.error("Minimum ₹ 500 Investment amount.");
      setLoading(false);
      return;
    }

    if (amount > 100000) {
      toast.error("Maximum ₹ 1,00,000 Deposit amount.");
      setLoading(false);
      return;
    }

    // Validate UPI ID
    if (!upiId.trim() || !validateUPI(upiId)) {
      toast.error("Please enter a valid UPI ID.");
      setLoading(false);
      return;
    }

    try {
      // Save the transaction with pending status to Firebase
      const transactionRef = await addDoc(
        collection(firestore, "Transactions"),
        {
          userId: uid,
          amount: parseFloat(amount),
          status: "pending",
          timestamp: new Date(),
          type: "deposit",
          id: new Date().getTime(),
          depositUpiId: upiId,
          depositMethod: "UPI",
          razorpay_payment_id: "",
        }
      );

      // Access the ID of the newly created transaction document
      const transactionId = transactionRef.id;
      toast.success("Deposit is in processing.");
      navigate("/transactions");
    } catch (error) {
      console.error("Error processing payment", error);
      toast.error("Error processing payment. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="max-w-md mx-auto p-4 rounded-md shadow-md">
      <div className="mb-4">
        <span
          className="text-white cursor-pointer"
          onClick={() => navigate(-1)}
        >
          &#8592; Back
        </span>
      </div>
      <Toaster toastOptions={{ duration: 2000 }} />

      <hr />
      <h2 className="text-white text-lg my-4">Deposit</h2>
      <div className="">
        <div className="mb-4 ">
          <label className="block text-sm font-medium text-white">
            Amount:
          </label>
          <div className="relative mt-2">
            <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-white">
              ₹
            </span>
            <input
              type="number"
              value={amount}
              onChange={(e) => {
                const enteredValue = e.target.value;
                const maxValue = 100000;

                if (
                  enteredValue === "" ||
                  (!isNaN(enteredValue) && parseFloat(enteredValue) <= maxValue)
                ) {
                  setAmount(e.target.value);
                }
              }}
              className="pl-7 bg-gray-700 h-12 text-white  outline-none  border-gray-300  p-2 w-full border rounded-md focus:outline-none focus:ring focus:border-blue-300"
              placeholder="Enter Amount"
            />
          </div>
        </div>

        {/* Deposit Method Selection */}
        <div className="flex mb-4 ">
          <button
            onClick={() => toggleDepositMethod("bank")}
            className={`flex-1 m-1 bg-green-600 py-2  text-white rounded ${
              depositMethod === "bank" ? "bg-opacity-80" : ""
            }`}
          >
            UPI(~10 min)
          </button>
          <button
            onClick={() => toggleDepositMethod("card")}
            className={`flex-1 m-1 bg-green-600 py-2 text-white rounded ${
              depositMethod === "card" ? "bg-opacity-80" : ""
            }`}
          >
            Card(Instantly)
          </button>
        </div>

        {/* UTR Number Input for Bank Deposit */}
        {depositMethod === "bank" && (
          <div className="mb-4 text-white">
            <img
              src={`https://chart.googleapis.com/chart?cht=qr&chs=100x100&chl=upi://pay?pa=rpy.tapcent2takainterest@icici&
  am=${amount}&cu=INR&chld=H|3`}
            />
            <p className="mb-4">UPI : rpy.tapcent2takainterest@icici</p>
            <div className=" text-white rounded">
              Pay Using
              <div className="grid grid-cols-4 gap-4">
                {paymentMethods.map((method) => (
                  <div
                    key={method.id}
                    className="bg-gray-800 p-4 rounded shadow-md"
                  >
                    <a href={method.url}>
                      <img
                        src={method.image}
                        alt={method.name}
                        className="mx-auto "
                      />
                    </a>
                  </div>
                ))}
              </div>
            </div>
            <label className="block text-sm mt-2 font-medium text-white">
              UPI ID:
            </label>
            <input
              type="text"
              value={upiId}
              onChange={(e) => setUpiId(e.target.value)}
              className="bg-gray-700  h-12 text-white outline-none border-gray-300 p-2 w-full border rounded-md focus:outline-none focus:ring focus:border-blue-300"
              placeholder="Enter your UPI ID "
            />
            <p className="text-red-500">
              * Enter you upi id which is used to pay the amount.
            </p>
          </div>
        )}

        {/* Pay Now Button */}
        <div>
          <button
            onClick={
              depositMethod === "card" ? handleCardPayment : handleBankPayment
            }
            disabled={loading}
            className={`bg-green-600 w-full flex gap-1 items-center justify-center py-2.5 text-white rounded ${
              loading ? "opacity-50 cursor-not-allowed" : ""
            }`}
          >
            {loading && <CgSpinner size={20} className="mt-1 animate-spin" />}
            <span>Pay Now</span>
          </button>
        </div>

        <div className="mt-4 text-gray-300 text-sm">
          <p>
            <FontAwesomeIcon icon={faShieldAlt} className="mr-2" />
            Your money is safe with us. We use secure payment gateways to
            process transactions.
          </p>
          <br />
          <p>
            <FontAwesomeIcon icon={faPhoneAlt} className="mr-2" />
            For any issues or concerns, please contact our support team.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Deposit;
