import React from "react";

const NotFound = () => {
  return (
    <div className="flex items-center justify-center h-screen bg-gray-100">
      <div className="text-center">
        <h1 className="text-6xl font-bold text-gray-800">404</h1>
        <p className="text-2xl font-semibold text-gray-600">
          Page not found
        </p>
        <p className="text-gray-500 mt-4">
          The page you are looking for might be in another universe.
        </p>
        <button
          onClick={() => window.history.back()}
          className="mt-8 px-6 py-3 bg-blue-500 text-white rounded-full hover:bg-blue-600 focus:outline-none focus:ring focus:border-blue-300"
        >
          Go Back
        </button>
      </div>
    </div>
  );
};

export default NotFound;
