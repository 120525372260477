import Cookies from "js-cookie";
import React from "react";
import { Navigate, Outlet } from "react-router-dom";

const Protected = () => {
  const token = localStorage.getItem("token");
  const cookie = Cookies.get("authToken");
  //console.log(cookie);
  //console.log(token);
  if (token && cookie && token === cookie) {
    return <Outlet />;
  } else {
    Cookies.remove("authToken");
    localStorage.removeItem("token");
    return <Navigate to="/login" />;
  }
};

export default Protected;
