import React from "react";
import {
  FaBars,
  FaHome,
  FaMoneyCheckAlt,
  FaPercent,
  FaPersonBooth,
  FaUser,
} from "react-icons/fa";
import { Link, Navigate, useLocation } from "react-router-dom";

const BottomNav = () => {
  const { pathname } = useLocation();

  return (
    <nav
      className="fixed bottom-0 rounded-t-lg w-full bg-gray-800"
      style={{
        maxWidth: "480px",
        margin: "0 auto",
      }}
    >
      <div className="flex justify-between  items-center">
        <Link
          to="/dashboard"
          className={`text-white w-2/4 h-14  p-2 flex flex-col items-center justify-center ${
            pathname === "/dashboard" || pathname === "/"
              ? "dark:bg-gray-700"
              : ""
          }`}
        >
          {/* <span role="img" aria-label="Home" className="text-xl"></span> */}
          <FaHome /> Home
        </Link>
        <Link
          to="/portfolio"
          className={`text-white w-2/4 h-14 p-2 flex flex-col items-center justify-center ${
            pathname === "/portfolio" ? "dark:bg-gray-700" : ""
          }`}
        >
          {/* <span role="img" aria-label="Account" className="text-xl">
            
          </span> */}
          <FaMoneyCheckAlt />
          Portfolio
        </Link>
        <Link
          to="/account"
          className={`text-white w-2/4 h-14 p-2 flex flex-col items-center justify-center ${
            pathname === "/account" ? "dark:bg-gray-700" : ""
          }`}
        >
          {/* <span role="img" aria-label="Account" className="text-xl">
            
          </span> */}
          <FaUser />
          Account
        </Link>
      </div>
    </nav>
  );
};

export default BottomNav;
