import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import Login from "./Pages/Login";
import Dashboard from "./Pages/Dashboard";
import Protected from "./Components/Protected";
import NotFound from "./Pages/NotFound";
import Account from "./Pages/Account";
import AccountDetails from "./Pages/AccountDetails";
import BankDetails from "./Pages/BankDetails";
import Withdrawal from "./Pages/Withdrawal";
import Deposit from "./Pages/Deposit";
import Transactions from "./Pages/Transactions";
import Portfolio from "./Pages/Portfolio";
import Investment from "./Pages/Investment";
import Details from "./Pages/InvestDetail";
import InvestDetail from "./Pages/InvestDetail";
import Referral from "./Pages/Referral";
import { TnC } from "./Pages/TnC";
import { PrivacyPolicy } from "./Pages/PrivacyPolicy";
import { Help, Support } from "./Pages/Support";
import PhoneAuth from "./Pages/PhoneAuth";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<App />}>
      <Route path="/login" element={<Login />} />
      <Route path="/terms-conditions" index element={<TnC />} />
      <Route path="/auth" index element={<PhoneAuth />} />

      <Route path="/login/:refCode" element={<Login />} />
      <Route path="/" element={<Protected />}>
        <Route path="/*" element={<NotFound />} />

        <Route path="/dashboard/*" index element={<Dashboard />} />
        <Route path="/account" index element={<Account />} />
        <Route path="/" index element={<Dashboard />} />
        <Route path="/account-details" index element={<AccountDetails />} />
        <Route path="/bank-details" index element={<BankDetails />} />
        <Route path="/withdrawal" index element={<Withdrawal />} />
        <Route path="/deposit" index element={<Deposit />} />
        <Route path="/transactions" index element={<Transactions />} />
        <Route path="/investment" index element={<Investment />} />
        <Route path="/details/:investId" index element={<InvestDetail />} />
        <Route path="/referral" index element={<Referral />} />
        <Route path="/privacy-policy" index element={<PrivacyPolicy />} />
        <Route path="/support" index element={<Support />} />
        <Route
          path="/portfolio"
          index
          element={<Portfolio userId={localStorage.getItem("token")} />}
        />
      </Route>
    </Route>
  )
);
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<RouterProvider router={router} />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
