import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useNavigate } from "react-router-dom";

export const TnC = () => {
  const navigate = useNavigate();

  return (
    <div className=" text-white max-w-2xl mx-auto p-4">
      <div className="mb-4">
        <span
          className="text-white cursor-pointer"
          onClick={() => navigate(-1)}
        >
          <FontAwesomeIcon icon={faArrowLeft} className="mr-2" />
          Back
        </span>
      </div>

      <h1 className="text-3xl font-bold mb-4">Terms and Conditions</h1>
      <p className="mb-4">
        Welcome to our financial services! These terms and conditions outline
        the rules and regulations for the use of our services.
      </p>

      <h5 className="text-xl font-bold mb-2">1. Acceptance of Terms</h5>
      <p>
        By accessing or using our financial services, you agree to comply with
        and be bound by these terms and conditions.
      </p>

      <h5 className="text-xl font-bold mb-2">2. Risks</h5>
      <p>
        All investments involve risks, and past performance is not indicative of
        future results. You acknowledge that you are aware of the risks
        associated with financial transactions and investments.
      </p>

      <h5 className="text-xl font-bold mb-2">3. Accuracy of Information</h5>
      <p>
        We strive to provide accurate and up-to-date information, but we do not
        warrant the accuracy or completeness of any information provided through
        our services.
      </p>
      <h5 className="text-xl font-bold mb-2">4. No Guarantee of Interest</h5>
      <p>
        We do not guarantee any interest on your deposits. The financial
        services provided do not involve an interest-bearing account. The value
        of your account may fluctuate based on market conditions.
      </p>

      <h5 className="text-xl font-bold mb-2">5. Modification of Terms</h5>
      <p>
        We reserve the right to modify these terms and conditions at any time.
        Changes will be effective immediately upon posting to the website. Your
        continued use of the services after the posting of changes constitutes
        your acceptance of such changes.
      </p>

      <h5 className="text-xl font-bold mb-2">6. Contact Us</h5>
      <p>
        If you have any questions or concerns about these terms and conditions,
        please contact us on telegram.
      </p>
    </div>
  );
};
